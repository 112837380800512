import React from "react";
import NewsSideBar from "../Components/NewsSideBar";

const NewsLayout = ({ children }) => {
  return (
    <div id="main" class="main-container">
      <div class="container">
        <div class="row">
          {children}
          <NewsSideBar />
        </div>
      </div>
    </div>
  );
};
export default NewsLayout;