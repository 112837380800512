import React, { useEffect, useState } from "react";
import "./Overs.css";
import { Link } from "react-router-dom";
import { BALL_TYPE_PANELTY_RUN, OTHER_BALLS } from "../utilities/const";
import { getRunClass } from "../utilities/functions";
import { PlayerImageInOvers } from "./PlayerImage";
import OversSkeleton from "./Skeleton/OversSkeleton";

const Overs = ({ OversData, loading, isShowClient }) => {
  const [players, setPlayers] = useState(undefined);
  const [teamsData, setTeamsData] = useState(undefined);
  const [oversDetails, setOversDetails] = useState();
  const [baid,setBaid] = useState(null);
  const [boid,setBoid] = useState(null);
  const [currentBowlingTeam, setCurrentBowlingTeam] = useState(undefined);
  const [currentBattingTeam, setCurrentBattingTeam] = useState(undefined);
  const [currentRuns, setCurrentRuns] = useState(0)
  const findPlayer = (playerId) => {
    const playerName = players?.find((val) => {
      return val?.commentaryPlayerId === playerId;
    });
    return playerName?.playerName;
  };
  const PlayerImages = (playerId) => {
    const playerImage = players?.find((val) => {
      return val?.commentaryPlayerId === playerId;
    });
    return playerImage?.playerimage;
  };
  const countCurrentRuns = (over, teamId) => {
    let overs = oversDetails[teamId]?.sort((a, b) => b?.ov - a?.ov)?.slice(-over?.ov, -1);
    let ctruns = overs?.reduce((acc, obj) => acc + obj.runs, 0)
    let wicketCounts = overs.reduce((acc, obj) => acc + obj.twk, 0)
    return { ctruns, wicketCounts }
  }
  const teamJersay = (teamId) => {
    const teamName = teamsData?.find((val) => {
      return val?.teamId == teamId;
    });
    return teamName?.jersey;
  };
  const TeamName = (teamId) => {
    const teamName = teamsData?.find((val) => {
      return val?.teamId !== teamId;
    });
    return teamName;
  };

  useEffect(() => {
    setPlayers(OversData?.players);
    const data = OversData?.teamsData.sort((a, b) => b?.currentInnings - a?.currentInnings);
    const pairs = [];
    for (let i = 0; i < data?.length; i += 2) {
      pairs.push([data[i], data[i + 1]]);
    }
    pairs.forEach((pair) => {
      pair.sort((a, b) => a.teamBattingOrder - b.teamBattingOrder);
    });
    const sortedArray = pairs.flat();
    const battingTeam = OversData?.teamsData.find(team => team.teamStatus === 1 && OversData?.summery?.currentInnings === team?.currentInnings);
    const bowlingTeam = OversData?.teamsData.find(team => team.teamStatus === 2 && OversData?.summery?.currentInnings === team?.currentInnings);
    setBaid(battingTeam?.teamId)
    setBoid(bowlingTeam?.teamId)
    setTeamsData(sortedArray);
    setCurrentBowlingTeam(bowlingTeam?.teamId);
    setCurrentBattingTeam(battingTeam?.teamId);
    setOversDetails(OversData?.overs)
  }, [OversData]);
  return (
    loading ? (
      <OversSkeleton />
    ) : ( oversDetails ? (
      <div className="row">
        {teamsData
          ?.sort((a, b) => b.currentInnings - a.currentInnings)
          .map((teamData) =>
            teamData.currentInnings > OversData?.summery?.currentInnings ? null :
              (
                <>
                  {OversData?.overs && OversData.overs[teamData?.teamId]?.length > 0 && <section className="live-matches col-12 col-md-12 col-lg-6 overs-row">
                    <div className="card card-shadow p-0">
                      <div className="table-responsive">
                        <table className="widget-table table no-border table-striped">
                          <thead>
                            <tr>
                              <div className="overs-header borderRadius">
                                <th
                                  colSpan={OversData?.overs?.length}
                                  className="text-left d-flex align-items-center py-3"
                                >
                                  <img
                                    className="teamFlag"
                                    src={
                                      TeamName(teamData?.teamId)?.image ||
                                      "/assets/images/flag.png"
                                    }
                                    alt="Team Image"
                                  />
                                  <h5 className="mb-0">
                                    {TeamName(teamData?.teamId)?.teamName || "Team Name"}
                                  </h5>
                                </th>
                              </div>
                            </tr>
                          </thead>
                          <tbody>
                            {OversData?.overs[teamData?.teamId]?.map((over, index) => {

                             const findPreviousScore = (currentIndex) => {
                              const overs = OversData?.overs[teamData?.teamId];
                              if (!overs || currentIndex < 0) return "NaN";

                              for (let i = currentIndex+1; i < overs.length; i++) {
                                const prevOver = overs[i];
                                if (prevOver?.teamScore !== null) {
                                  return prevOver.teamScore;
                                }
                              }
                              return "NaN";
                              };
                              
                                return over.currentInnings === teamData?.currentInnings ? (
                                  <tr
                                    key={index}
                                    className="d-flex align-Items-center py-1"
                                  >
                                    <div className="d-flex align-items-center">
                                      <PlayerImageInOvers
                                        width="35px"
                                        playerImage={PlayerImages(over?.bowlerId)}
                                        jerseyImage={
                                          teamJersay(teamData?.teamId) ||
                                          "/images/SampleJersy.png"
                                        }
                                      />
                                    </div>
                                    <div className="py-1 width-full">
                                      <div class="d-flex align-items-center justify-content-between py-3 height-0">
                                        <div>
                                          <td className="mb-2">
                                            <span className="mr-2">
                                              <strong className="font-sm-cm-1 rounded px-2 border-black bg-gray">
                                                {over?.over+1}
                                              </strong>
                                            </span>
                                            {isShowClient ? <span className="font-sm-cm">
                                              {findPlayer(over?.bowlerId) || "Bowler Name "}
                                            </span>: null}
                                          </td>
                                        </div>
                                        <div class="w-10 px-3">
                                          <span class="text-nowrap font-weight-bold font-sm-cm">
                                            {over?.totalRun} runs | {over?.totalWicket <= 0 ? 0 : over?.totalWicket} wicket <span className="ml-2">
                                              [{over?.teamScore ? over?.teamScore : findPreviousScore(index)}]
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="">
                                        {over?.ball
                                          // .sort((a, b) => a.overCount - b.overCount) // Sort the array in ascending order by overCount
                                          .sort((a, b) => parseFloat(a.commentaryBallByBallId) - parseFloat(b.commentaryBallByBallId))
                                          .filter((item)=>item.ballType != 10)
                                          .map((balls, ballIndex) => {
                                            const previousBall = over?.ball?.sort((a, b) => a.overCount - b.overCount)[ballIndex - 1];
                                            const nextball = over?.ball?.sort((a, b) => a.overCount - b.overCount)[ballIndex + 1];
                                            if (nextball && nextball.ballIsWicket && nextball.overCount === balls.overCount) {
                                              return null;
                                            }
                                            return over.ball?.length == 1 ? (
                                              <td>Yet to Ball</td>
                                            ) : ballIndex === 0 ? null : (
                                              <td key={ballIndex}>
                                                <div>
                                                  <span
                                                    className={`
                                ${getRunClass(
                                                      balls?.ballType,
                                                      balls?.ballExtraRun,
                                                      balls?.ballIsBoundry,
                                                      balls?.ballRun,
                                                      balls?.ballIsWicket
                                                    )}
                                px-2 py-1 shadow-sm rounded border-gray mr-1`}
                                                  >
                                                    {Object.keys(OTHER_BALLS).includes(
                                                      String(balls.ballType)
                                                    )
                                                      ? (balls.ballRun > 0
                                                        ? balls.ballRun + " "
                                                        : "") +
                                                      "" +
                                                      OTHER_BALLS[balls.ballType]
                                                      : balls.ballIsWicket
                                                        ? previousBall && previousBall.overCount === balls.overCount
                                                          ? `${previousBall?.ballRun > 0 ? previousBall.ballRun + " " : ""}${OTHER_BALLS[previousBall.ballType] || 0} | W`
                                                          : "w"
                                                        : balls.ballType == 9 ? "RH"
                                                        : balls.ballType == 10 ? ""
                                                          : balls.ballRun}
                                                  </span>
                                                </div>
                                              </td>
                                            )
                                          })}
                                      </div>
                                    </div>
                                  </tr>
                                ) : null;
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>}
                </>
              ))}
      </div>
    ) : (
    <div className="card card-shadow p-0">
      <div className="score-card-inner flex-grow-1 px-20 py-20 text-center">
        <strong> Match has not started yet </strong>
      </div>
    </div>
    )
    )
  );
};

export default Overs;
