import React, { useEffect, useState } from "react";
import axiosInstance from "../Features/axios";
import DynamicRoutesSkeleton from "../Components/Skeleton/DynamicRoutesSkeleton";

const DynamicRoutes = ({ alias }) => {
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(true);

  document.title = `${pageData?.pageName}`;

  useEffect(() => {
    const fetchPagesContent = async () => {
      try {
        const response = await axiosInstance.post(
          `/admin/menuItem/getPageContent`,
          {
            alias,
          }
        );
        const pageContent = response.data?.result;
        setPageData(pageContent);
      } catch (error) {
        console.error("Error fetching page content:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPagesContent();
  }, [alias]);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-TMN4CW60YD`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-TMN4CW60YD');
  }, [alias]);

  return (
    <>
        {loading ? (
          <DynamicRoutesSkeleton />
        ) : (
          <div class="col-lg-9">
            <section class="live-matches pt-0">
              <div class="card card-shadow">
                {pageData.pageName}
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.pageContent,
                  }}
                />
              </div>
            </section>
          </div>
        )}
    </>
  );
};

export default DynamicRoutes;
