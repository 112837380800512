import React from "react";
import NewsLayout from "../Layouts/NewsLayout";
import NewsDeatilsSection from "../Components/NewsDeatilsSection";

const NewsDetails = () => {
  return (
    <NewsLayout>
      <NewsDeatilsSection />
    </NewsLayout>
  );
};
export default NewsDetails;
