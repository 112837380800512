import React from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import "./Timer.css";

const CountdownTimer = ({ scoreData }) => {
  const matchStartTime = scoreData?.utc;

  const StartTime = new Date(scoreData?.utc).getTime();
  const currentTime = new Date().getTime();

  const matchHasStarted = StartTime && currentTime > StartTime;

  return (
    <div className="timer-css">
      {matchHasStarted ? (
        <span>Match has already started</span>
      ) : (
        <div className="mt-2">
          <span>Starting</span>
          <FlipClockCountdown to={matchStartTime} className="flip-clock my-2 d-flex justify-content-center" />
        </div>
      )}
    </div>
  );
};

export default CountdownTimer;
