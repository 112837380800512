import React, { useEffect, useState } from "react";
import axiosInstance from "../Features/axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import { Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import "./banner.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import BannerSkeleton from "./Skeleton/BannerSkeleton";
import { useAppContext } from "../Context/AppContext";

const NewsSidebarContent = () => {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { cmsData } = useAppContext();

  useEffect(() => {
    setNewsData(cmsData?.news);
    setLoading(false);
  }, [cmsData?.news]);

  const activityViewerFunc = async (newsId, encodedTitle) => {
    try {
      const viewedNews =
        JSON.parse(localStorage.getItem("viewedNews")) || [];

      if (!viewedNews.includes(newsId)) {
        const payload = { refId: newsId };
        const response = await axiosInstance.post(
          `/admin/newsViewers/save`,
          payload
        );
        console.log("response", response?.data?.result);
        localStorage.setItem(
          "viewedNews",
          JSON.stringify([...viewedNews, newsId])
        );
      } else {
        console.log("News already viewed");
      }
    } catch (error) {
      console.error("Error fetching page content:", error);
    }
  };
  return (
    <div className="row banner">
      {loading ? (
        <BannerSkeleton />
      ) : (
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          freeMode={true}
          className="mySwiper"
          centeredSlidesBounds={false}
          centeredSlides={false}
          navigation={true}
          modules={[FreeMode, Navigation]}
          breakpoints={{
            200: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          {newsData &&
            newsData
              ?.filter((item) => item?.isActive && (new Date(item?.endDate) > new Date() || item?.isPermanent))
              ?.map((item) => {
                const encodedTitle = item?.title
                  ? encodeURIComponent(
                      item?.title
                        .toLowerCase()
                        .replace(/[^a-zA-Z0-9\s]/g, "")
                        .replace(/\s+/g, "-")
                        .replace(/-{2,}/g, "-")
                        .trim()
                    )
                  : "";
                return (
                  <SwiperSlide key={item.newsId}>
                    <Link
                      to={`/news/${item.newsId}/${encodedTitle}`}
                      onClick={() =>
                        activityViewerFunc(item.newsId, encodedTitle)
                      }
                    >
                      <div class="card card-shadow p-0 news-card-radius">
                        <div class="score-card score-card-lg p-0 news-card-radius">
                          <strong className="news-display">LATEST NEWS</strong>
                          <div class="score-card-inner pt-1 px-05">
                            <div class="score-card-header">
                              <figure>
                                <img
                                  src={item?.image || "/assets/images/flag.png"}
                                  className="news-card"
                                  alt=""
                                />
                              </figure>
                              <strong className="pt-1">{item?.title}</strong>
                              {/* <span>
                                {
                                  convertDateTimeUTCToLocal(item?.startDate)
                                    .localDate
                                }
                              </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                );
              })}
        </Swiper>
      )}
    </div>
  );
};

export default NewsSidebarContent;
