import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import axiosInstance from "../Features/axios";
import { useParams } from "react-router-dom";
import "./fullscore.css";
import { CONNECT, CONNECT_EVENT, CONNECT_MARKET, UPDATE_EVENT, UPDATE_EVENT_MARKET } from "../utilities/const";
import FullScoreSidebarContentSkeleton from "./Skeleton/FullScoreSidebarContentSkeleton";

const FullScoreSidebarContent = () => {
  const [token, setToken] = useState("");
  const [openMarket, setOpenMarket] = useState([]);
  const [settledMarket, setSettledMarket] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataProviderUrl, setDataProviderUrl] = useState("");
  const [marketIds, setMarketIds] = useState([]);
  const [marketTypeCategory, setMarketTypeCategory] = useState([]);
  const paramsData = useParams();
  const socket = useRef(null);

  useEffect(()=>{
    const tokenVal = localStorage.getItem("accessToken");
    setToken(tokenVal || "");
  }, [])

  const groupMarketsByTeamAndInnings = (markets) => {
    const groupedMarkets = {};
    markets.forEach((market) => {
      const key = `${market.teamId}-${market.inningsId}`;
      if (!groupedMarkets[key]) {
        groupedMarkets[key] = [];
      }
      groupedMarkets[key].push(market);
      setMarketIds((prevIds) => [...prevIds, market.marketId]);
    });
    return groupedMarkets;
  };

  const groupSettledMarketsByTeamAndInnings = (markets) => {
    const groupedMarkets = {};
    markets.forEach((market) => {
      const key = `${market.teamId}-${market.inningsId}`;
      if (!groupedMarkets[key]) {
        groupedMarkets[key] = [];
      }
      groupedMarkets[key].push(market);
    });
    return groupedMarkets;
  };

  const fetchLoginCred = async () => {
    try {
      const response = await axiosInstance.post(`/api/configData`);
      const marketType = response?.data?.result?.MarketTypeAndCategory || [];
      setMarketTypeCategory(marketType);
    } catch (error) {
      console.error("Error fetching config data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoginCred();
  }, []);

  useEffect(() => {
    const fetchMarketContent = async (eventId) => {
      try {
        const response = await axiosInstance.post(`/admin/match/getMarketByCId`, { eventId });
        if (response.data) {
          const openMarketData = response.data?.result?.openMarkets || [];
          const settledMarketData = response.data?.result?.settledMarkets || [];
          const groupedOpenMarketsData = groupMarketsByTeamAndInnings(openMarketData);
          const groupedSettledMarketsData = groupSettledMarketsByTeamAndInnings(settledMarketData);
          setOpenMarket(openMarketData || []);
          setSettledMarket(groupedSettledMarketsData);
          setDataProviderUrl(response.data?.result?.dataProviderUrl)
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    if (paramsData?.id) {
      fetchMarketContent(paramsData.id);
    }
  }, [paramsData?.id]);

  // useEffect(() => {
  //   let socket = null;

  //   if (dataProviderUrl) {
  //     socket = io.connect(dataProviderUrl, {
  //       transports: ["websocket"]
  //     });

  //     if (marketIds.length > 0) {
  //       if (socket) {
  //         socket.emit("connectMarket", {
  //           marketIds: marketIds,
  //         });
  //         socket.on("updateEventMarket", (data) => {
  //           // console.log("Received updated event market data:", data);
  //           const updatedOpenMarket = { ...openMarket };
  //           // console.log("updatedOpenMarket",updatedOpenMarket);
  //           const marketToUpdate = Object.values(updatedOpenMarket).flat().find(item => item.marketId == data?.data?.marketId);
  //           // console.log("marketToUpdate",marketToUpdate);
  //           if (marketToUpdate) {
  //             if (data.data.status === 4) {
  //               const eventKey = Object.keys(updatedOpenMarket).find(key => updatedOpenMarket[key].some(market => market.marketId == marketToUpdate.marketId));
  //               updatedOpenMarket[eventKey] = updatedOpenMarket[eventKey].filter(item => item.marketId != marketToUpdate.marketId);
  //             } else {
  //               marketToUpdate.layPrice = data?.data?.runner[0]?.layPrice;
  //               marketToUpdate.laySize = data?.data?.runner[0]?.laySize;
  //               marketToUpdate.backPrice = data?.data?.runner[0]?.backPrice;
  //               marketToUpdate.backSize = data?.data?.runner[0]?.backSize;
  //             }
  //             setOpenMarket(updatedOpenMarket);
  //           }
  //         });
  //       }
  //     }
  //   }

  //   return () => {
  //     if (socket) {
  //       socket.disconnect();
  //     }
  //   };
  // }, [marketIds]);

  const configSocket = (eventId) => {
    socket.current.emit(CONNECT_EVENT, {
      eventIds: [eventId],
    });
    socket.current.on(UPDATE_EVENT, (data) => {
      // console.log("Received updated event data:", data);
      const markets = data?.data?.markets || [];

      setOpenMarket((prevOpenMarket) => {
        const updatedOpenMarket = [...prevOpenMarket];

        markets?.forEach((marketData) => {
          const marketIndex = updatedOpenMarket.findIndex(
            (item) => item.marketId == marketData?.marketId
          );
          if (marketIndex !== -1) {
            if (marketData?.status == 4 || marketData?.status == 5 || marketData?.status == 6) {
              // const eventKey = Object.keys(updatedOpenMarket).find((key) => updatedOpenMarket[key].some((market) => market.marketId == marketToUpdate.marketId));
              updatedOpenMarket.splice(marketIndex, 1);
            } else {
              const marketToUpdate = updatedOpenMarket[marketIndex];
              if (marketData?.runner) {
                marketToUpdate.layPrice = marketData?.runner[0]?.layPrice || "0";
                marketToUpdate.laySize = marketData?.runner[0]?.laySize || "0";
                marketToUpdate.backPrice = marketData?.runner[0]?.backPrice || "0";
                marketToUpdate.backSize = marketData?.runner[0]?.backSize || "0";
                marketToUpdate.status = marketData?.status;
              }
            }
          } else {
            if (marketData?.runner && marketData?.status != 4 && marketData?.status != 5 && marketData?.status != 6) {
              const newMarket = {
                marketId: marketData?.marketId,
                marketName: marketData?.marketName,
                openOdds: marketData?.openOdds || 0,
                layPrice: marketData?.runner[0]?.layPrice,
                laySize: marketData?.runner[0]?.laySize,
                backPrice: marketData?.runner[0]?.backPrice,
                backSize: marketData?.runner[0]?.backSize,
                status: marketData?.status,
                marketTypeCategoryId: marketData?.marketTypeCategory,
              };
              updatedOpenMarket.push(newMarket);
            }
          }
        });
        return updatedOpenMarket;
      });
    });
  };

  useEffect(() => {
    if (dataProviderUrl) {
      socket.current = io.connect(dataProviderUrl, {
        transports: ["websocket"],
      });

      if (paramsData?.id) {
        if (socket.current?.connected) {
          configSocket(paramsData?.id);
        } else {
          socket.current.on(CONNECT, () => {
            configSocket(paramsData?.id);
          });
        }
      }
    }

    // return () => {
    //   if (socket.current) {
    //     socket.current.disconnect();
    //   }
    // };
  }, [dataProviderUrl, paramsData?.id]);

  return token ? (
    loading ? (
      <FullScoreSidebarContentSkeleton login={false} />
    ) : (
    <div className="acr-body">
      <>
        {marketTypeCategory?.length > 0 &&
          marketTypeCategory
            ?.sort((a, b) => a.displayOrder - b.displayOrder)
            ?.map((marketType) => {
              const hasRelevantMarkets = marketType?.marketTypeCategories.some(subCategory => 
                openMarket?.some(item => item.marketTypeCategoryId === subCategory.id && item.status !== 4)
              );
              if (!hasRelevantMarkets) return null;
              return (
                <div key={marketType?.id} className="card card-shadow p-0 table-responsive table-scrollbar">
                  {marketType?.marketTypeCategories?.length > 0 && marketType?.marketTypeCategories?.sort((a, b) => a.displayOrder - b.displayOrder)?.map((subCategory) => {
                    const relevantMarkets = openMarket.filter(
                      (item) => item.marketTypeCategoryId === subCategory.id && item.status !== 4 && item.marketTypeCategoryId != 26 && item.marketTypeCategoryId != 27
                    );
                    if (relevantMarkets.length === 0) return null;
                    return (
                        <div key={subCategory?.id} className="sidebar-category">
                            <table className="widget-table table market-table table-striped no-border">
                              <thead className="sub-category">
                                <tr>
                                  <th scope="col">{subCategory?.marketTypeCategori}</th>
                                  <th scope="col">Open</th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {relevantMarkets?.filter((item) => item.status !==4 )?.map((data, index) => (
                                  <tr key={index}>
                                    <td>{data.marketName.toUpperCase().replace("RUNS", "")}</td>
                                    <td>{parseInt(data?.status) === 1 ? <>{data?.openOdds ? data.openOdds : null}</> : null}</td>
                                    <td className="pr-0">
                                    {parseInt(data?.status) === 1 ? 
                                      <div className="d-flex align-items-center justify-content-center">
                                        <div className="no-rate">
                                          <div className="rate-font">{data.layPrice || "0"}</div>
                                          <div className="point-font">{data.laySize || "0"}</div>
                                        </div>
                                        <div className="yes-rate">
                                          <div className="rate-font">{data.backPrice || "0"}</div>
                                          <div className="point-font">{data.backSize || "0"}</div>
                                        </div>
                                      </div>
                                    : <div className="d-flex align-items-center justify-content-center market-suspended-container">
                                        <div className="no-rate-suspend"></div>
                                        <div className="yes-rate-suspend"></div>
                                        <div className="overlay">
                                          <span className="suspended-text">Market suspend</span>
                                        </div>
                                      </div>}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                        </div>
                    );
                  })}
                </div>
              );
          })}
      </>
      {Object.keys(settledMarket).map((key) => {
          const [teamId, inningsId] = key.split("-");
          return (
            <div key={key}>
              <div class="card card-shadow p-0 rounded-20">
                <h5 className="d-flex align-items-center px-05 py-05">
                  <div className="flag-avatar mr-2">
                    <figure>
                      <img
                        src={settledMarket[key][0]?.teamImage || "/assets/images/flag.png"}
                        alt="Team Image"
                      />
                    </figure>
                  </div>
                  <span className="font-black">{settledMarket[key][0]?.teamName}{+inningsId !== 1 && +inningsId !== 0 && `, Innings ${inningsId}`}</span>
                </h5>
                {marketTypeCategory?.length > 0 &&
                  marketTypeCategory
                    ?.sort((a, b) => a.displayOrder - b.displayOrder)
                    ?.map((marketType) => {
                      const hasRelevantMarkets = marketType?.marketTypeCategories.some((subCategory) =>
                          settledMarket[key]?.some((item) => item.marketTypeCategoryId === subCategory.id)
                        );
                      if (!hasRelevantMarkets) return null;
                      return (
                        <div key={marketType?.id} className="table-responsive table-scrollbar">
                          {marketType?.marketTypeCategories?.length > 0 && marketType?.marketTypeCategories?.sort((a, b) => a.displayOrder - b.displayOrder)?.map((subCategory) => {
                                const relevantMarkets = settledMarket[key]?.filter(
                                  (item) => item.marketTypeCategoryId === subCategory.id
                                );
                                if (relevantMarkets?.length === 0) return null;
                                return (
                                  <div key={subCategory?.id} className="sidebar-category">
                                      <table className="widget-table table settled-table market-table table-striped no-border">
                                        <thead className="sub-category">
                                          <tr>
                                            <th scope="col">{subCategory?.marketTypeCategori}</th>
                                            <th scope="col">Open</th>
                                            <th scope="col">Min</th>
                                            <th scope="col">Max</th>
                                            <th scope="col">Result</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {relevantMarkets?.sort((a,b)=>a.marketId - b.marketId)?.map((data, index) => (
                                              <tr key={index}>
                                                <td>{data.marketName.toUpperCase().replace("RUNS", "")}</td>
                                                <td>{data?.openOdds? Math.round(+data.openOdds): "0"}</td>
                                                <td>{data?.minOdds? Math.round(+data.minOdds): "0"}</td>
                                                <td>{data?.maxOdds? Math.round(+data.maxOdds): "0"}</td>
                                                <td><div className="d-flex justify-content-between mx-2">
                                                    <span style={{fontSize: "medium", color: +data.openOdds < +data.result ? "green" : "red"}}>
                                                      {+data.openOdds < +data.result ? "<" : ">"}
                                                    </span>
                                                    <strong style={{color: +data.openOdds < +data.result ? "green" : "red"}}>
                                                      {data?.result ? data.result : "0"}
                                                    </strong>
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                  </div>
                                );
                              })}
                        </div>
                      );
                    })}
              </div>
            </div>
          );
        })}
    </div>)
  ) : ( openMarket?.length > 0 && <FullScoreSidebarContentSkeleton login={true} /> );
};

export default FullScoreSidebarContent;