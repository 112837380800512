import React, { useEffect, useState } from "react";
import axiosInstance from "../Features/axios";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { USER_DATA_KEY } from "../utilities/const";
import OtpInput from "react-otp-input";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToast from "../Components/Reusables/CustomToast";

const VerifyOtp = () => {
  document.title = "VerifyOtp";
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [error, setError] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const userDataVal = localStorage.getItem(USER_DATA_KEY);
    if (userDataVal) {
      setUserData(JSON.parse(userDataVal));
    }
  }, []);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (otp.length !== 4) {
      console.error("Please enter a valid OTP");
      return;
    }

    const payload = {
      otp: otp,
      email: userData?.emailId,
      isMobileVerify: userData?.isMobileVerify || false,
      isEmailVerify: userData?.isEmailVerify || false,
    };

    try {
      const response = await axiosInstance.post(`/api/verifyOtp`, payload);
      if (response?.data?.result) {
        setError(null);
        toast.success(<CustomToast title={response?.data?.title} message={response?.data?.result || "Otp verified successfully."} />);
        navigate("/setPassword");
      } else {
        setError("Invalid OTP. Please try again.");
        toast.error(<CustomToast title={response?.data?.error?.code} message={response?.data?.error?.message || "Invalid Otp"}/>);
      }
    } catch (error) {
      console.error("Verification failed", error);
      toast.error(<CustomToast title={error?.code} message={error?.message || "Verification failed"}/>);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async (event) => {
    event.preventDefault();

    const payload = {
      email: userData?.emailId,
    };

    try {
      const response = await axiosInstance.post(`/api/resendOtp`, payload);
      if (response?.data?.result) {
        toast.success(<CustomToast title={response?.data?.title} message={response?.data?.result || "Otp sent successfully."} />);
      } else {
        setError("Request failed. Please try again.");
        toast.error(<CustomToast title={response?.data?.error?.code} message={response?.data?.error?.message || "Otp resend failed"}/>);
      }
    } catch (error) {
      console.error("Request failed", error);
      toast.error(<CustomToast title={error?.code} message={error?.message || "Otp resend failed"}/>);
    } finally {
      setLoading(false);
    }
  };

  return (
      <div class="col-lg-9">
        <div className="card card-shadow">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-8 col-xl-6">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-3">
                  <h3 className="mb-5 text-center">
                    Please enter the otp we have send to your registered {userData?.isMobileVerify ? "mobile number" : "email id"}
                  </h3>
                  <form onSubmit={handleFormSubmit}>
                    <div className="d-flex justify-content-center mt-4">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        isInputNum
                        shouldAutoFocus
                        containerStyle="mb-4"
                        inputStyle="form-control form-control-lg otp-css"
                      />
                    </div>
                    {error && <p className="text-danger text-center">{error}</p>}

                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-primary btn-lg btn-block mr-3"
                        type="submit"
                        style={{
                          marginTop: "0.5rem"
                        }}
                      >
                        Verify OTP
                      </button>
                      <button
                        className="btn btn-success btn-lg btn-block"
                        onClick={handleResendOtp}
                      >
                        Resent OTP
                      </button>
                    </div>
                  </form>
                  <hr className="my-4" />
                </div>
              </div>
            </div>
          </div>
        <ToastContainer />
        </div>
      </div>
  );
};

export default VerifyOtp;
