import React, { useEffect, useState } from "react";
import PlayerImage from "./PlayerImage";
import LiveTeamSkeleton from "./Skeleton/LiveTeamSkeleton";

const LiveTeams = ({ teamData, loading }) => {
  const [team1Players, setTeam1Players] = useState([]);
  const [team2Players, setTeam2Players] = useState([]);

  useEffect(() => {
    setTeam1Players(teamData?.sqt1?.sort((a, b) => a.ord - b.ord));
    setTeam2Players(teamData?.sqt2?.sort((a, b) => a.ord - b.ord));
  }, [teamData]);

  return loading ? (
    <LiveTeamSkeleton />
  ) : ( teamData && (
    <div>
      <div className="accordion row" id="accordion">
        <div className="accordion-item col-12 col-md-12 col-lg-6">
          <h5
            className=""
            data-toggle="collapse"
            data-target="#ind_innings"
            aria-expanded="true"
          >
            <div className="flag-avatar">
              <figure>
                <img
                  src={teamData?.t1img || "/assets/images/flag.png"}
                  alt="Team 1"
                />
              </figure>
            </div>
            {teamData.te1n}
          </h5>
          <div
            id="ind_innings"
            className="collapse show"
          // data-parent="#accordion"
          >
            <div className="acr-body">
              <div className="card card-shadow p-0">
                <div class="row">
                  {team1Players &&
                    team1Players?.filter((item)=> item?.currentInnings == teamData?.es?.currentInnings && item?.isInPlayingEleven)
                    ?.map((item) => (
                      <div class="col-6 col-md-4 col-lg-4">
                        <div className="card card-height p-2">
                          <div class="d-flex align-items-center">
                            <PlayerImage
                              width="40px"
                              playerImage={item?.playerimage}
                              jerseyImage={teamData?.t1jr}
                            />
                            <div>
                              <div class="font-weight-bold">{item.playerName}</div>
                              <div>{item.playerType}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item col-12 col-md-12 col-lg-6">
          <h5
            className=""
            data-toggle="collapse"
            data-target="#bd_innings"
            aria-expanded="true"
          >
            <div className="flag-avatar">
              <figure>
                <img
                  src={teamData?.t2img || "/assets/images/flag.png"}
                  alt="Team 2 Image"
                />
              </figure>
            </div>
            {teamData.te2n}
          </h5>
          <div id="bd_innings" className="collapse show"
          // data-parent="#accordion"
          >
            <div className="acr-body">
              <div className="card card-shadow p-0">
                <div class="row">
                  {team2Players &&
                    team2Players?.filter((item)=> item?.currentInnings == teamData?.es?.currentInnings && item?.isInPlayingEleven)
                    ?.map((item) => (
                      <div class="col-6 col-md-4 col-lg-4">
                        <div className="card card-height p-2">
                          <div class="d-flex align-items-center">
                            <PlayerImage
                              width="40px"
                              playerImage={item?.playerimage}
                              jerseyImage={teamData?.t2jr}
                            />
                            <div>
                              <div class="font-weight-bold">{item.playerName}</div>
                              <div>{item.playerType}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))
};

export default LiveTeams;
