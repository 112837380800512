import React from "react";
import FullscoreSideBar from "../Components/FullscoreSideBar";

const FullScoreLayout = ({ children }) => {
  return (
    <div id="main" class="main-container">
      <div class="container">
        <div class="row">
          {children}
          <FullscoreSideBar />
        </div>
      </div>
    </div>
  );
};
export default FullScoreLayout;