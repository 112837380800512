import React, { useEffect, useState } from "react";
import axiosInstance from "../Features/axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Autoplay} from "swiper/modules";
import { Link } from "react-router-dom";
import "./banner.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import BannerSkeleton from "./Skeleton/BannerSkeleton";
import { useAppContext } from "../Context/AppContext";

const BannerContent = () => {
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { cmsData } = useAppContext();

  useEffect(() => {
    setBannerData(cmsData?.banner);
    setLoading(false);
  }, [cmsData?.banner]);

  const activityViewerFunc = async (bannerId, encodedTitle) => {
    try {
      const viewedBanners =
        JSON.parse(localStorage.getItem("viewedBanners")) || [];

      if (!viewedBanners.includes(bannerId)) {
        const payload = { refId: bannerId };
        const response = await axiosInstance.post(
          `/admin/bannerViewers/save`,
          payload
        );
        console.log("response", response?.data?.result);
        localStorage.setItem(
          "viewedBanners",
          JSON.stringify([...viewedBanners, bannerId])
        );
      } else {
        console.log("Banner already viewed");
      }
    } catch (error) {
      console.error("Error fetching page content:", error);
    }
  };

  return (
    <div className="row banner">
      {loading ? (
        <BannerSkeleton />
      ) : (
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          freeMode={true}
          className="mySwiper"
          centeredSlidesBounds={false}
          centeredSlides={false}
          navigation={true}
          modules={[FreeMode, Navigation, Autoplay]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            200: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          {bannerData &&
            bannerData
              ?.filter((item) => item?.isActive && (new Date(item?.endDate) > new Date() || item?.isPermanent))
              ?.map((item) => {
                const encodedTitle = item?.title
                  ? encodeURIComponent(
                      item?.title
                        .toLowerCase()
                        .replace(/[^a-zA-Z0-9\s]/g, "")
                        .replace(/\s+/g, "-")
                        .replace(/-{2,}/g, "-")
                        .trim()
                    )
                  : "";
                return (
                  <SwiperSlide key={item.bannerId}>
                    <Link
                      to={item?.link}
                      style={{cursor: item?.link ? "pointer" : "default"}}
                      onClick={() =>
                        activityViewerFunc(item.bannerId, encodedTitle)
                      }
                    >
                      {/* <div class="card card-shadow p-0"> */}
                        {/* <div class="score-card score-card-lg p-0"> */}
                          {/* <div class="score-card-inner px-0 py-0"> */}
                            {/* <div class="score-card-header"> */}
                              <figure>
                                <img
                                  src={item?.image || "/assets/images/flag.png"}
                                  className="banner-card mb-10"
                                  alt=""
                                />
                              </figure>
                              {/* <strong className="my-2">{item?.title}</strong> */}
                              {/* <span>
                                {
                                  convertDateTimeUTCToLocal(item?.startDate)
                                    .localDate
                                }
                              </span> */}
                            {/* </div> */}
                          {/* </div> */}
                        {/* </div> */}
                      {/* </div> */}
                    </Link>
                  </SwiperSlide>
                );
              })}
        </Swiper>
      )}
    </div>
  );
};

export default BannerContent;
