import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const FullscoreChart = ({ graphData }) => {
  const [team1, setTeam1] = useState([]);
  const [team2, setTeam2] = useState([]);
  const [team3, setTeam3] = useState([]);
  const [team4, setTeam4] = useState([]);
  const [showLineCurrentInnings, setShowLineCurrentInnings] = useState(true);
  const [showBarCurrentInnings, setShowBarCurrentInnings] = useState(true);
  const [team1Color, setTeam1Color] = useState(null);
  const [team2Color, setTeam2Color] = useState(null);
  
  useEffect(() => {
    if (graphData?.summery && graphData?.oversData?.length > 0) {
      const team1Data = graphData?.oversData
        ?.sort((a, b) => a.overId - b.overId)
        ?.filter(
          (over) =>
            over.teamId === graphData?.summery.team1Id &&
            over.currentInnings === graphData?.summery?.currentInnings /* &&
            over.isComplete */
        );      
      const team2Data = graphData?.oversData
        ?.sort((a, b) => a.overId - b.overId)
        ?.filter(
          (over) =>
            over.teamId === graphData?.summery.team2Id &&
            over.currentInnings === graphData?.summery?.currentInnings /* &&
            over.isComplete */
        );
      const team3Data = graphData?.oversData
        ?.sort((a, b) => a.overId - b.overId)
        ?.filter(
          (over) =>
            over.teamId === graphData?.summery.team1Id &&
            over.currentInnings !== graphData?.summery?.currentInnings /* &&
            over.isComplete */
        );
      const team4Data = graphData?.oversData
        ?.sort((a, b) => a.overId - b.overId)
        ?.filter(
          (over) =>
            over.teamId === graphData?.summery.team2Id &&
            over.currentInnings !== graphData?.summery?.currentInnings /* &&
            over.isComplete */
        );
      const team1Color = graphData?.teamsData?.find((item)=>item.teamId === graphData?.summery.team1Id);
      setTeam1Color(team1Color?.teamColor);
      
      const team2Color = graphData?.teamsData?.find((item)=>item.teamId === graphData?.summery.team2Id);
      setTeam2Color(team2Color?.teamColor);
      
      setTeam1(team1Data || []);
      setTeam2(team2Data || []);
      setTeam3(team3Data || []);
      setTeam4(team4Data || []);
    }
  }, [graphData?.oversData, graphData?.summery, graphData?.teamsData]);

  const mapDataWithWickets = (data) => {
    const overs =
      data?.length > 0
        ? data?.map((over) => ({
            x: over?.over + 1,
            y: over?.teamScore && parseInt(over?.teamScore.split("/")[0]),
            score: over?.teamScore,
            runs: over?.totalRun,
            wicket: over?.totalWicket,
            hasWicket: over?.totalWicket > 0,
          }))
        : [];
    const oversDataa = overs.length > 0 ? [{ x: 0, y: 0, score: "0/0", runs: 0, wicket: 0, hasWicket: false }, ...overs] : [];
    return oversDataa;
  };
  const lineSeries = showLineCurrentInnings
    ? [
        {
          name: graphData?.summery?.team2Name,
          data: mapDataWithWickets(team1),
        },
        {
          name: graphData?.summery?.team1Name,
          data: mapDataWithWickets(team2),
        },
      ]
    : [
        {
          name: `${graphData?.summery?.team2Name} (Previous Innings)`,
          data: mapDataWithWickets(team3),
        },
        {
          name: `${graphData?.summery?.team1Name} (Previous Innings)`,
          data: mapDataWithWickets(team4),
        },
      ];

  const mapDataForBar = (data) => {
    const overs =
      data?.length > 0
        ? data?.map((over) => ({
            x: over?.over + 1,
            y: over?.totalRun,
            score: over?.teamScore,
            runs: over?.totalRun,
            wicket: over?.totalWicket,
            hasWicket: over?.totalWicket > 0,
          }))
        : [];
    return overs;
  };
  const barSeries = showBarCurrentInnings
    ? [
        {
          name: graphData?.summery?.team2Name,
          data: mapDataForBar(team1),
        },
        {
          name: graphData?.summery?.team1Name,
          data: mapDataForBar(team2),
        },
      ]
    : [
        {
          name: `${graphData?.summery?.team2Name} (Previous Innings)`,
          data: mapDataForBar(team3),
        },
        {
          name: `${graphData?.summery?.team1Name} (Previous Innings)`,
          data: mapDataForBar(team4),
        },
      ];

  const title =
    graphData?.summery?.team1Name || graphData?.summery?.team2Name
      ? `Runs per Over - ${graphData?.summery?.team1Name} vs ${graphData?.summery?.team2Name}`
      : "Runs per Over";

  const getWicketAnnotations1 = (data) => {
    return data
      .filter((over) => over.hasWicket)
      .map((over) => ({
        x: over.x,
        y: over.y,
        marker: {
          size: 5,
          fillColor: "#FF0000"/* team1Color */,
          strokeColor: "#FF0000",
        },
        label: {
          borderColor: "#FF0000",
          style: {
            color: "#FFF",
            background: "#FF0000",
          },
          text: "w",
        },
      }));
  };
  const getWicketAnnotations2 = (data) => {
    return data
      .filter((over) => over.hasWicket)
      .map((over) => ({
        x: over.x,
        y: over.y,
        marker: {
          size: 5,
          fillColor: "#FF0000"/* team2Color */,
          strokeColor: "#FF0000",
        },
        label: {
          borderColor: "#FF0000",
          style: {
            color: "#FFF",
            background: "#FF0000",
          },
          text: "w",
        },
      }));
  };
  const getWicketAnnotationsTeam1 = (data) => {
    return data
      .filter((over) => over.hasWicket)
      .map((over) => ({
        x: over.x - 0.16,
        y: over.y,
        marker: {
          size: 4,
          fillColor: "#FF0000"/* team1Color */,
          strokeColor: "#FF0000",
        },
        label: {
          borderColor: "#FF0000",
          style: {
            color: "#FFF",
            background: "#FF0000",
          },
          text: "w",
        },
      }));
  };
  const getWicketAnnotationsTeam2 = (data) => {
    return data
      .filter((over) => over.hasWicket)
      .map((over) => ({
        x: over.x + 0.18,
        y: over.y,
        marker: {
          size: 4,
          fillColor: "#FF0000"/* team2Color */,
          strokeColor: "#FF0000",
        },
        label: {
          borderColor: "#FF0000",
          style: {
            color: "#FFF",
            background: "#FF0000",
          },
          text: "w",
        },
      }));
  };
  const maxOver = Math.ceil((Math.max(...team1.map(p => p.over), ...team2.map(p => p.over), ...team3.map(p => p.over), ...team4.map(p => p.over)) + 1 ) / 5) * 5;
  const overNo = (() => {
  if (maxOver <= 5) {
      return maxOver - 1;
    } else if (maxOver <= 10) {
      return Math.ceil(maxOver / 2) - 1;
    } else if (maxOver <= 15) {
      return Math.ceil(maxOver / 3) - 1;
    } else if (maxOver <= 20) {
      return Math.ceil(maxOver / 4) - 1;
    } else if (maxOver <= 25) {
      return Math.ceil(maxOver / 5) - 1;
    } else if (maxOver <= 30) {
      return Math.ceil(maxOver / 6) - 1;
    } else {
      return Math.ceil(maxOver / 5) - 1;
    }
  })()
  const lineOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
      },
      background: "#ffffff",
      events: {
        zoomed: function (chartContext, { xaxis }) {
          const zoomMinOver = Math.floor((xaxis.min) / 5) * 5;
          const zoomMaxOver = Math.ceil((xaxis.max + 1) / 5) * 5;
          const zoomOverNo = (() => {
            const range = zoomMaxOver - zoomMinOver;
            if (range <= 5) {
                return range - 1;
              } else if (range <= 10) {
                return Math.ceil(range / 2) - 1;
              } else if (range <= 15) {
                return Math.ceil(range / 3) - 1;
              } else if (range <= 20) {
                return Math.ceil(range / 4) - 1;
              } else if (range <= 25) {
                return Math.ceil(range / 5) - 1;
              } else if (range <= 30) {
                return Math.ceil(range / 6) - 1;
              } else {
                return Math.ceil(range / 5) - 1;
              }
          })()
          chartContext.updateOptions({
            xaxis: {
              min: zoomMinOver,
              max: zoomMaxOver,
              tickAmount: zoomOverNo,
              labels: {
                formatter: function (val) {
                  return val.toFixed(1) + " ov";
                },
              },
            },
          });
        },
        legendClick: function (chartContext, seriesIndex, config) {
          const isTeam1Visible = chartContext.w.globals.series[0].length !== 0;
          const isTeam2Visible = chartContext.w.globals.series[1].length !== 0;
          let updatedAnnotations = [];
          if (seriesIndex === 0) {
            if (!isTeam1Visible) {
              updatedAnnotations.push(...getWicketAnnotations1(lineSeries[0].data));
            }
            if (isTeam2Visible) {
              updatedAnnotations.push(...getWicketAnnotations2(lineSeries[1].data));
            }
          } else if (seriesIndex === 1) {
            if (!isTeam2Visible) {
              updatedAnnotations.push(...getWicketAnnotations2(lineSeries[1].data));
            }
            if (isTeam1Visible) {
              updatedAnnotations.push(...getWicketAnnotations1(lineSeries[0].data));
            }
          }
          chartContext.updateOptions({
            annotations: {
              points: updatedAnnotations,
            },
          });
          return false;
        },
      },
    },
    title: {
      text: title,
      align: "left",
      style: {
        fontSize: "16px",
      },
    },
    xaxis: {
      title: {
        text: "Overs",
      },
      min: 0,
      max: maxOver,
      tickAmount: overNo,
      labels: {
        formatter: function (val) {
          // return `${val} ov`;
          return val.toFixed(1) + " ov";
        },
      },
      // categories: team1.map((point) => point.x),
    },
    yaxis: {
      title: {
        text: "Runs",
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y, { series, seriesIndex, dataPointIndex, w }) {
          const overData = w.config.series[seriesIndex].data[dataPointIndex];
          const totalRuns = overData?.runs || 0;
          const teamScore = overData?.score || "0/0";
          const totalWicket = overData?.wicket || 0;
          return `${totalRuns} runs | ${totalWicket} wicket [${teamScore}]`;
        },
      },
    },
    annotations: {
      yaxis: [],
      points: [
        ...getWicketAnnotations1(lineSeries[0].data),
        ...getWicketAnnotations2(lineSeries[1].data),
      ],
    },
    stroke: {
      width: 3,
    },
    colors: [team2Color || "#008FFB", team1Color || "#00E396"],
    legend: {
      position: "top",
      horizontalAlign: "right",
      onItemClick: {
        toggleDataSeries: true,
      },
    },
  };
  const barOptions = {
    chart: {
      type: "bar",
      zoom: {
        enabled: true,
      },
      background: "#ffffff",
      events: {
        zoomed: function (chartContext, { xaxis }) {
          const zoomMinOver = Math.floor((xaxis.min) / 5) * 5;
          const zoomMaxOver = Math.ceil((xaxis.max + 1) / 5) * 5;
          const zoomOverNo = (() => {
            const range = zoomMaxOver - zoomMinOver;
            if (range <= 5) {
                return range - 1;
              } else if (range <= 10) {
                return Math.ceil(range / 2) - 1;
              } else if (range <= 15) {
                return Math.ceil(range / 3) - 1;
              } else if (range <= 20) {
                return Math.ceil(range / 4) - 1;
              } else if (range <= 25) {
                return Math.ceil(range / 5) - 1;
              } else if (range <= 30) {
                return Math.ceil(range / 6) - 1;
              } else {
                return Math.ceil(range / 5) - 1;
              }
          })()
          chartContext.updateOptions({
            xaxis: {
              min: zoomMinOver,
              max: zoomMaxOver,
              tickAmount: zoomOverNo,
              labels: {
                formatter: function (val) {
                  return val.toFixed(1) + " ov";
                },
              },
            },
          });
        },
        legendClick: function (chartContext, seriesIndex, config) {
          const isTeam1Visible = chartContext.w.globals.series[0].length !== 0;
          const isTeam2Visible = chartContext.w.globals.series[1].length !== 0;
          let updatedAnnotations = [];
          if (seriesIndex === 0) {
            if (!isTeam1Visible) {
              updatedAnnotations.push(...getWicketAnnotationsTeam1(barSeries[0].data));
            }
            if (isTeam2Visible) {
              updatedAnnotations.push(...getWicketAnnotationsTeam2(barSeries[1].data));
            }
          } else if (seriesIndex === 1) {
            if (!isTeam2Visible) {
              updatedAnnotations.push(...getWicketAnnotationsTeam2(barSeries[1].data));
            }
            if (isTeam1Visible) {
              updatedAnnotations.push(...getWicketAnnotationsTeam1(barSeries[0].data));
            }
          }
          chartContext.updateOptions({
            annotations: {
              points: updatedAnnotations,
            },
          });
          return false;
        },
      },
    },
    title: {
      text: title,
      align: "left",
      style: {
        fontSize: "16px",
      },
    },
    xaxis: {
      title: {
        text: "Overs",
      },
      // categories: team1.map((point) => point.x),
      min: 0,
      max: maxOver,
      tickAmount: overNo,
      labels: {
        formatter: function (val) {
          return val.toFixed(1) + " ov";
        },
      },
    },
    yaxis: {
      title: {
        text: "Runs",
      },
      min: 0,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      shared: false,
      intersect: true,
      y: {
        formatter: function (y, { series, seriesIndex, dataPointIndex, w }) {
          const overData = w.config.series[seriesIndex].data[dataPointIndex];
          const totalRuns = overData?.runs || 0;
          const teamScore = overData?.score || "0/0";
          const totalWicket = overData?.wicket || 0;
          return `${totalRuns} runs | ${totalWicket} wicket [${teamScore}]`;
        },
      },
    },
    annotations: {
      points: [
        ...getWicketAnnotationsTeam1(barSeries[0].data),
        ...getWicketAnnotationsTeam2(barSeries[1].data),
      ],
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    colors: [team2Color || "#008FFB", team1Color || "#00E396"],
    legend: {
      position: "top",
      horizontalAlign: "right",
      onItemClick: {
        toggleDataSeries: true,
      },
    },
  };

  const handleLineToggleInnings = () => {
    setShowLineCurrentInnings(!showLineCurrentInnings);
  };

  const handleBarToggleInnings = () => {
    setShowBarCurrentInnings(!showBarCurrentInnings);
  };

  return (
    <>
      {team3?.length > 0 && team4?.length > 0 ? (
        <button
          type="button"
          className="btn btn-light"
          onClick={handleLineToggleInnings}
        >
          {showLineCurrentInnings
            ? "Show Previous Innings"
            : "Show Current Innings"}
        </button>
      ) : null}
      <Chart
        options={lineOptions}
        series={lineSeries}
        type="line"
        height={350}
      />
      {team3?.length > 0 && team4?.length > 0 ? (
        <button
          type="button"
          className="btn btn-light"
          onClick={handleBarToggleInnings}
        >
          {showBarCurrentInnings
            ? "Show Previous Innings"
            : "Show Current Innings"}
        </button>
      ) : null}
      <Chart options={barOptions} series={barSeries} type="bar" height={350} />
    </>
  );
};

export default FullscoreChart;