import { useLocation } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import MatchSideBar from "../Components/MatchSideBar";

const AppLayout = ({ children }) => {
  const location = useLocation();
  const hideHeaderRoutes = ["/verify-email", "/scoreboard", "/scoreboard2"];
  const shouldHideHeader = hideHeaderRoutes.includes(location.pathname);
  const shouldHideMatchSidebar = location.pathname.startsWith("/news") || location.pathname.startsWith("/full-score");  
  return (
    <>
      {!shouldHideHeader && <Header />}

      {shouldHideHeader || shouldHideMatchSidebar ? (
        <>{children}</>
      ) : (
        <div id="main" className="main-container">
          <div className="container">
            <div className="row">
              {children}
              <MatchSideBar />
            </div>
          </div>
        </div>
      )}

      {!shouldHideHeader && <Footer />}
    </>
  );
};
export default AppLayout;