import BannerContent from "./BannerContent";
import NewsSidebarContent from "./NewsSidebarContent";

const MatchSideBar = () => {
  return (
    <div class="col-lg-3">
      <aside class="sidebar right-sidebar">
        <BannerContent />
        <NewsSidebarContent />
      </aside>
    </div>
  )
}

export default MatchSideBar;