import React, { useEffect, useState } from "react";
import { getCommentaryRun, getSocketFullRuns, getRunClass, getMarketType } from "../utilities/functions";
import PlayerImage from "./PlayerImage";
import CommentaryOverSkeleton from "./Skeleton/CommentaryOverSkeleton";
import { OTHER_BALLS } from "../utilities/const";
import { convertDateUTCToLocal } from "./Reusables/Methods";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const CommentaryDetails = ({ commentaryOverData, loading, isShowClient }) => {
    const [token, setToken] = useState("");
    const navigate = useNavigate();

    useEffect(()=>{
        const tokenVal = localStorage.getItem("accessToken");
        setToken(tokenVal || "")
    }, [])
    const getPlayerImage = (sqt, pid) => {
        let player = sqt?.find(player => player.commentaryPlayerId === pid);
        return player?.playerimage;
    }
    const getPlayerName = (sqt, playerId) => {
        if (sqt && playerId) {
          let playerName = sqt?.find((val) => {
            return val.commentaryPlayerId === playerId;
          });
          return playerName?.playerName;
        }
      };
    const TeamName = (teamId) => {
      const teamName = commentaryOverData?.teamsData?.find((val) => {
        return val?.teamId !== teamId;
      });
      return teamName;
    };
    return  loading ? (
        <CommentaryOverSkeleton />
      ) : ( commentaryOverData?.td.length > 0 ? (
            <div className="accordion" id="accordion">
                {commentaryOverData?.td?.filter((value => value.teamBattingOrder !== null))?.map((data, index) => {
                    const sqt1 = ((data.commentaryTeamId === commentaryOverData?.t1id || data.commentaryTeamId === commentaryOverData?.t3id) ? commentaryOverData?.sqt1 : commentaryOverData?.sqt2)
                    const sqt2 = (sqt1 === commentaryOverData?.sqt1 ? commentaryOverData?.sqt2 : commentaryOverData?.sqt1)
                    return (<div className="accordion-item">
                        <h5 className="" data-toggle="collapse" data-target={`#bd_innings-${index}`} aria-expanded="true">
                        <div className="flag-avatar">
                            <figure>
                                <img
                                    src={
                                        TeamName(data?.teamId)?.image ||
                                      "/assets/images/flag.png"
                                    }
                                    alt="Team Image"
                                />
                            </figure>
                        </div>
                        {TeamName(data?.teamId)?.teamName || "Team Name"}
                        </h5>
                        <div id={`bd_innings-${index}`} className="collapse show">
                            <div className="acr-body">
                                <section className="pt-0">
                                    <div className="widget widget-rankings">
                                        <div>
                                            {commentaryOverData?.ov.filter(a => a.teamId == data.teamId && a.currentInnings == data.currentInnings)?.sort((a,b)=> b?.overId - a?.overId)?.map((data2, index) => {
                                                const findPreviousScore = (currentIndex) => {
                                                    const overs = commentaryOverData?.ov.filter(a => a.teamId == data.teamId && a.currentInnings == data.currentInnings);
                                                    if (!overs || currentIndex < 0) return "NaN";
                      
                                                    for (let i = currentIndex+1; i < overs.length; i++) {
                                                      const prevOver = overs[i];
                                                      if (prevOver?.teamScore !== null) {
                                                        return prevOver.teamScore;
                                                      }
                                                    }
                                                    return "NaN";
                                                };
                                                return (<>
                                                    <div className="commentary-box">
                                                        <div className="commentary-header">
                                                            <h5 className="d-flex align-items-center">
                                                                Over {data2.over+1}
                                                                <PlayerImage
                                                                    width="40px"
                                                                    playerImage={getPlayerImage(sqt1, data2.bowlerId)}
                                                                    jerseyImage={data.jersey}
                                                                />
                                                                {isShowClient ? <p className="ml-20">{getPlayerName(sqt1, data2.bowlerId)}</p>: null}
                                                            </h5>
                                                            <p>
                                                                {data2.totalRun} runs | {data2.totalWicket} wicket
                                                                <span className="ml-2">[{data2?.teamScore ? data2?.teamScore : findPreviousScore(index)}]</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="commentary-body">
                                                        <ul className="commentary-list">
                                                            {data2.ball?.sort((a, b) => parseFloat(b.commentaryBallByBallId) - parseFloat(a.commentaryBallByBallId)).filter((item)=>item.ballType != 10).map((overBallDetails, index) => {
                                                                const previousBall = data2?.ball?.sort((a, b) => b.overCount - a.overCount)[index + 1];
                                                                const nextball = data2?.ball?.sort((a, b) => b.overCount - a.overCount)[index - 1];
                                                                if (nextball && nextball.ballIsWicket && nextball.overCount === overBallDetails.overCount) {
                                                                   return null;
                                                                }
                                                                const collapseId = `session-${overBallDetails.commentaryBallByBallId}`;
                                                                const modalId = `modal-${overBallDetails.commentaryBallByBallId}`;
                                                                const marketData = commentaryOverData?.marketData && commentaryOverData?.marketData?.filter((item)=>item.commentaryBallByBallId === overBallDetails.commentaryBallByBallId && item.rateSource == 2)?.sort((a, b) => a.id - b.id);
                                                                const sessionData = commentaryOverData?.marketData && commentaryOverData?.marketData?.filter((item)=>item.commentaryBallByBallId === overBallDetails.commentaryBallByBallId && item.rateSource != 2)?.sort((a, b) => a.id - b.id);
                                                                return (
                                                                    overBallDetails?.ballType !== 0 ?
                                                                        (<div key={index}>
                                                                          <li>
                                                                            <div>
                                                                                <h5>{parseFloat(overBallDetails.overCount).toFixed(1)}</h5>
                                                                                <span className={getRunClass(overBallDetails.ballType, overBallDetails.ballExtraRun, overBallDetails.ballIsBoundry, overBallDetails.ballRun, overBallDetails.ballIsWicket)}>
                                                                                    {/* {getSocketFullRuns(overBallDetails)} */}
                                                                                    {overBallDetails && Object.keys(OTHER_BALLS).includes(String(overBallDetails.ballType))
                                                                                    ? (overBallDetails.ballRun > 0 
                                                                                      ? overBallDetails.ballRun + " " : "") + "" +
                                                                                    OTHER_BALLS[overBallDetails.ballType]
                                                                                    : overBallDetails.ballIsWicket 
                                                                                      ? previousBall && previousBall.overCount === overBallDetails.overCount
                                                                                        ? `${previousBall?.ballRun > 0 ? previousBall.ballRun + " " : ""}${OTHER_BALLS[previousBall.ballType] || 0} | W`
                                                                                        : "w"
                                                                                    : overBallDetails.ballType == 9 ? "RH"
                                                                                    : overBallDetails.ballType == 10 ? ""
                                                                                      : overBallDetails.ballRun}
                                                                                    </span>
                                                                            </div>
                                                                            <p>
                                                                                {isShowClient ? `${getPlayerName(sqt1, data2.bowlerId)} to ${getPlayerName(sqt2, overBallDetails.batStrikeId)},` : null} {getCommentaryRun(overBallDetails.ballType, overBallDetails.ballIsBoundry, overBallDetails.ballRun, overBallDetails.ballIsWicket)}.
                                                                                <br/> {token && <h6>{convertDateUTCToLocal(overBallDetails?.createdDate,"index")}</h6>}
                                                                            </p>
                                                                            {token && 
                                                                            <table className="widget-table table table-striped no-border">
                                                                            {/* <thead>
                                                                               <tr>
                                                                                  <th scope="col">Session</th>
                                                                                  <th scope="col">Open</th>
                                                                                  <th scope="col"></th>
                                                                                </tr>
                                                                            </thead> */}
                                                                            <tbody>
                                                                            { marketData.length > 0 && marketData?.map((data, index)=> {
                                                                                const market = data?.data && JSON.parse(data?.data);
                                                                                const team1 = market && market?.find((item) => item.teamId == commentaryOverData?.team1Id);
                                                                                const team2 = market && market?.find((item) => item.teamId == commentaryOverData?.team2Id);
                                                                                const drawTeam = market && market?.find((item) => item.RunnerName == "The Draw");
                                                                                return (team1 || team2) ? (
                                                                                <tr key={index}>
                                                                                  <td><h6 className="market-team">{data?.marketName?.toUpperCase().replace("RUNS", "")}</h6></td>
                                                                                  {/* <td><h6>{getMarketType(+data?.marketTypeId)}</h6></td> */}
                                                                                  <td><div className="d-flex flex-column align-items-start">
                                                                                    <h6 className="market-team">{team1?.RunnerName}</h6>
                                                                                    <h6 className="market-team">{team2?.RunnerName}</h6>
                                                                                    {drawTeam ? <h6 className="market-team">{drawTeam?.RunnerName}</h6> : null}</div></td>
                                                                                  <td><div className="d-flex flex-column">
                                                                                    <div className="d-flex align-items-center">
                                                                                       <div className="lay-price-card lay-radius px-2">{team1?.LayPrice}</div>
                                                                                       <div className="back-price-card back-radius px-2">{team1?.BackPrice}</div>
                                                                                    </div>
                                                                                    <div className="d-flex align-items-center my-1">
                                                                                       <div className="lay-price-card lay-radius px-2">{team2?.LayPrice}</div>
                                                                                       <div className="back-price-card back-radius px-2">{team2?.BackPrice}</div>
                                                                                    </div>
                                                                                    {drawTeam ? <div className="d-flex align-items-center">
                                                                                       <div className="lay-price-card lay-radius px-2">{drawTeam?.LayPrice}</div>
                                                                                       <div className="back-price-card back-radius px-2">{drawTeam?.BackPrice}</div>
                                                                                    </div> : null}
                                                                                  </div></td>
                                                                                </tr>) : null
                                                                            })}
                                                                            </tbody>
                                                                            </table>}
                                                                            {(!token && (sessionData.length > 0 || marketData.length > 0)) ? <div className="skeleton-bg" data-toggle="modal" data-target={`#${modalId}`}>
                                                                              <Skeleton width={200} height={30} />
                                                                              <button class="btn" type="button">
                                                                                <img src="/images/downarrow-dropdown1.svg" alt="Dropdown arrow" />
                                                                              </button>
                                                                            </div> : null}
                                                                            {(token && sessionData.length > 0) ?
                                                                              <button class="btn" type="button" data-toggle="collapse" data-target={`#${collapseId}`} aria-expanded="false" aria-controls={collapseId}>
                                                                                <img src="/images/downarrow-dropdown1.svg" alt="Dropdown arrow" />
                                                                              </button>: null}
                                                                        </li>
                                                                        {token ? <li className="d-flex flex-row-reverse">
                                                                              <table className="widget-table table table-striped no-border collapse" id={collapseId}>
                                                                                <tbody>
                                                                                {sessionData.length > 0 && sessionData.map((data, index)=> {
                                                                                  const sessionValue = data?.data && JSON.parse(data?.data);
                                                                                  return (sessionValue && sessionValue[0]) && (
                                                                                    <tr key={index}>
                                                                                      <td><h6 className="market-team px-4">{sessionValue[0]?.RunnerName?.toUpperCase().replace("RUNS", "")}</h6></td>
                                                                                      <td><div className="d-flex align-items-center"><><div className="no-rate"><div className="rate-font">{sessionValue[0]?.LayPrice ? sessionValue[0].LayPrice : "0"}</div> <div className="point-font">{sessionValue[0]?.LaySize ? sessionValue[0].LaySize : "0"}</div>  </div>
                                                                                          <div className="yes-rate"><div className="rate-font">{sessionValue[0]?.BackPrice ? sessionValue[0].BackPrice : "0"}</div> <div className="point-font">{sessionValue[0]?.BackSize ? sessionValue[0].BackSize : "0"}</div></div></></div></td>
                                                                                    </tr>
                                                                                  )
                                                                                })}
                                                                                </tbody>
                                                                              </table>
                                                                        </li> : <div class="modal fade" id={modalId} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                                          <div class="modal-dialog modal-dialog-centered session" role="document">
                                                                            <div class="modal-content">
                                                                              <div class="modal-header">
                                                                                <h4 class="modal-title" id="exampleModalCenterTitle">Login required</h4>
                                                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                                  <span aria-hidden="true">&times;</span>
                                                                                </button>
                                                                              </div>
                                                                              <div class="modal-body text-center py-4">
                                                                                <div className="msg py-3">Please login to view Match odds & Session data.</div>
                                                                                <div className="d-flex align-items-center justify-content-center py-3">
                                                                                <button type="button" class="btn btn-secondary btn-lg mx-1" data-dismiss="modal">Close</button>
                                                                                <button type="button" class="btn btn-primary btn-lg mx-1" data-dismiss="modal" onClick={()=>{ navigate('/login') }}>Login</button>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>}
                                                                        </div>)
                                                                        : null
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                    )
                })}
            </div>
    ) : (
      <div className="card card-shadow p-0">
        <div className="score-card-inner flex-grow-1 px-20 py-20 text-center">
          <strong> Match has not started yet </strong>
        </div>
      </div>
    )
    );
};

export default CommentaryDetails;