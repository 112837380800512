import React from "react";
import NewsContent from "../Components/NewsContent";
import NewsLayout from "../Layouts/NewsLayout";

const News = () => {
  document.title = 'News';
  return (
    <NewsLayout>
      <NewsContent />
    </NewsLayout>
  );
};
export default News;
