import React from 'react';

const Pagination = ({ totalRecords, page, limit, setPage, onShowSizeChange }) => {
  const totalPages = Math.ceil(totalRecords / limit);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handlePageSizeChange = (event) => {
    const newLimit = Number(event.target.value);
    onShowSizeChange(1, newLimit);
  };

  const generatePageNumbers = () => {
    const pages = [];
    const delta = 1;
    const left = Math.max(1, page - delta);
    const right = Math.min(totalPages, page + delta);

    if (left > 1) {
      pages.push(1);
      if (left > 2) pages.push('...');
    }

    for (let i = left; i <= right; i++) {
      pages.push(i);
    }

    if (right < totalPages) {
      if (right < totalPages - 1) pages.push('...');
      pages.push(totalPages);
    }

    return pages;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <nav aria-label="Page navigation example">
      <ul className="d-flex align-items-center justify-content-center pagination">
        <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
          <a
            className="page-link"
            href="#"
            aria-label="Previous"
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(page - 1);
            }}
          >
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>

        {pageNumbers.map((num, index) => (
          <li key={index} className={`page-item ${num === page ? 'active' : ''}`}>
            <a
              className="page-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                if (typeof num === 'number') {
                  handlePageChange(num);
                }
              }}
            >
              {num}
            </a>
          </li>
        ))}

        <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
          <a
            className="page-link"
            href="#"
            aria-label="Next"
            onClick={(e) => {
              e.preventDefault();
              handlePageChange(page + 1);
            }}
          >
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </a>
        </li>

        <li className="page-item mx-2">
          <select className="custom-select" value={limit} onChange={handlePageSizeChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </select>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;