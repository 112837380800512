import _, { isEmpty } from "lodash";
import { BALL_TYPE_PANELTY_RUN, LIVE, OTHER_BALLS, OTHER_BALLS_FULL_NAME, RESULT, STRING_NAME, UPCOMMING } from "./const";
import moment from "moment-timezone";

export const getMatchDetails = (scores) => {
  if (scores) {
    let matches = scores.split(" & ").map((match) => match.trim());

    // Extracting innings details for each match
    let inningsDetails = [...matches];

    // Extracting final innings details for the last match
    let finalMatchDetails = matches.pop().split("(");
    let overs = finalMatchDetails[1].split(")")[0];
    let runWicketDetails = finalMatchDetails[0].split("/");
    let runs = parseInt(runWicketDetails[0]);
    let wickets = parseInt(runWicketDetails[1]);
    let previousInnings = matches.reverse();
    return {
      inningsDetails,
      previousInnings,
      runs,
      wickets,
      overs,
    };
  }
};

export const wicketType = {
  1: "Bowled",
  2: "Catch",
  3: "Stump",
  4: "Hit Wicket",
  5: "LBW",
  6: "Run Out",
  7: "Retired Out",
  8: "Timed Out",
  9: "Hit Ball Twice",
  10: "Obstruct the Fielding",
}

export const getRunsAndBalls = (scores) => {
  let runs = scores.split("(");
  let balls = runs[1].split(")");

  return {
    runs: runs[0],
    balls: balls[0],
  };
};

export const getCurrentOver = (over, allBalls) => {
  if (allBalls) {
    let toSend = []
    let currentBall = allBalls.find((value) => value.ocn == over);
    const getBallType = (isb, run, isw, bty) => {
      if (bty == BALL_TYPE_PANELTY_RUN) return "bg-dark";
      if (Object.keys(OTHER_BALLS).includes(String(bty))) return "bg-warning";
      if (isb) return "bg-success";
      if (isw) return "bg-danger";
      if (bty == 0) return "bg-gray";
      if (bty == 9) return "bg-warning";
      if (run) return "";
      return "";
    };
    if (currentBall) {
      const currentOver = allBalls.sort((a, b) => parseInt(a.bbi) - parseInt(b.bbi)).filter(
        (value) => /* value.oid === currentBall.oid && */ !value.isdel && value.bty != 10
      );
      toSend = currentOver.map((value,index) => {
        const previousBall = currentOver?.[index - 1];
        return {
        ...value,
        ballType: getBallType(value.isb, value.run, value.isw, value.bty),
        actualRun: value.run,
        run: Object.keys(OTHER_BALLS).includes(String(value.bty))
          ? (value.run > 0 ? value.run + " " : "") +
          "" +
          OTHER_BALLS[value.bty]
          : value.isw
            ? previousBall && previousBall.ocn === value.ocn
              ? `${previousBall?.run > 0 ? previousBall.run + " " : ""}${OTHER_BALLS[previousBall.bty] || 0} | W`
              : "w"
            : value.bty == 9 ? "RH"
            : value.bty == 10 ? ""
            : value.bty == 0
              ? `${(+value.ocn+1 || 0)} ov`
              : value.run
        }
      });
    }
    const result = filterOvers2(toSend)
    return result
  }
};

export const getCurrentOverWithoutOverNo = (over, allBalls) => {
  if (allBalls) {
    let toSend = []
    let currentBall = allBalls.find((value) => value.ocn == over);
    const getBallType = (isb, run, isw, bty) => {
      if (bty == BALL_TYPE_PANELTY_RUN) return "bg-dark";
      if (Object.keys(OTHER_BALLS).includes(String(bty))) return "bg-warning";
      if (isb) return "bg-success";
      if (isw) return "bg-danger";
      if (bty == 9) return "bg-warning";
      if (run) return "";
      return "";
    };
    if (currentBall) {
      const currentOver = allBalls.sort((a, b) => parseInt(a.bbi) - parseInt(b.bbi)).filter(
        (value) => (value.bty !== 0 && value.bty != 10) && (value.oid === currentBall.oid) && !value.isdel
      );
      toSend = currentOver.map((value, index) => {
        const previousBall = currentOver?.[index - 1];
        return {
        ...value,
        ballType: getBallType(value.isb, value.run, value.isw, value.bty),
        actualRun: value.run,
        run: Object.keys(OTHER_BALLS).includes(String(value.bty))
          ? (value.run > 0 ? value.run + " " : "") +
          "" +
          OTHER_BALLS[value.bty]
          : value.isw
            ? previousBall && previousBall.ocn === value.ocn
              ? `${previousBall?.run > 0 ? previousBall.run + " " : ""}${OTHER_BALLS[previousBall.bty] || 0} | W`
              : "w"
            : value.bty == 9 ? "RH"
            : value.bty == 10 ? ""
            : value.bty == 0
              ? `${(+value.ocn || 0).toFixed(1)} OV`
              : value.run
        }      
      });
    }
    const result = filterOvers2(toSend)
    return result
  }
};


export const getOvers = (over, allBalls) => {
  let toSend = {}
  let currentBall = {}
  if (!isEmpty(allBalls)) {
    const getBallType = (isb, run, isw, bty) => {
      if (bty == BALL_TYPE_PANELTY_RUN) return "bg-dark";
      if (Object.keys(OTHER_BALLS).includes(String(bty))) return "bg-warning";
      if (isb) return "bg-success";
      if (isw) return "bg-danger";
      if (bty == 0) return "bg-gray over-ball-style";
      if (bty == 9) return "bg-warning";
      if (run) return "";
      return "";
    };
    let currentOver = _.orderBy(allBalls, ["bbi"], ["asc"]).filter(e => !e.isdel && e.bty != 10)
    currentOver.forEach((value, index) => {
      const ballOvetToAdd = Math.floor(value.ocn) + 1
      const previousBall = currentOver?.[index - 1];
      const ballDetailsToUpdate = {
        ...value,
        index,
        opacity: 0.3 + (index / (currentOver?.length || 0.3)),
        ballType: getBallType(value.isb, value.run, value.isw, value.bty),
        actualRun: value.run,
        run: Object.keys(OTHER_BALLS).includes(String(value.bty))
          ? (value.run > 0 ? value.run + " " : "") +
          "" +
          OTHER_BALLS[value.bty]
          : value.isw
            ? previousBall && previousBall.ocn === value.ocn
              ? `${previousBall?.run > 0 ? previousBall.run + " " : ""}${OTHER_BALLS[previousBall.bty] || 0} | W`
              : "w"
            : value.bty == 9 ? "RH"
            : value.bty == 10 ? ""
            : value.bty == 0
              ? `Ov ${(+value.ocn + 1 || 0).toFixed(1)}`
            : value.run
      }
      if (index === currentOver.length - 1) currentBall = ballDetailsToUpdate
      toSend[ballOvetToAdd] = [].concat((toSend[ballOvetToAdd] || []), [ballDetailsToUpdate])
    })
  }
  toSend["currentBall"] = currentBall
  const result = filterOvers(toSend)
  return result
};

export const getRunClass = (bty, ext, isB, runs, wik) => {
  if (bty) {
    if (bty == BALL_TYPE_PANELTY_RUN) return "bg-dark";
    if (Object.keys(OTHER_BALLS).includes(String(bty))) return "bg-warning";
    if (isB) return "bg-success";
    if (wik) return "bg-danger";
    if (bty == 9) return "bg-warning";
    if (runs) return "";
    return "";
  }
};

// export const filterOvers = (overs) => {
//   Object.keys(overs).forEach(overKey => {
//     if (overKey !== 'currentBall') {
//       overs[overKey] = overs[overKey].reduce((acc, currentBall) => {
//         if (currentBall.isw) {
//           acc = acc.filter(ball => ball.ocn !== currentBall.ocn);
//         }
//         acc.push(currentBall);
//         return acc;
//       }, []);
//     }
//   });
//   return overs;
// };

export const filterOvers = (overs) => {
  Object.keys(overs).forEach(overKey => {
    if (overKey !== 'currentBall') {
      const filteredBalls = [];
      for (let i = 0; i < overs[overKey].length; i++) {
        const currentBall = overs[overKey][i];
        if (currentBall.isw && i > 0) {
          const previousBall = overs[overKey][i - 1];
          if (previousBall.ocn === currentBall.ocn) {
            filteredBalls.pop(); 
          }
        }
        filteredBalls.push(currentBall);
      }
      overs[overKey] = filteredBalls;
    }
  });
  return overs;
};

// export const filterOvers2 = (overs) => {
//   overs = overs.reduce((acc, currentBall) => {
//     if (currentBall.isw) {
//       acc = acc.filter((ball) => ball.ocn !== currentBall.ocn);
//     }
//     acc.push(currentBall);
//     return acc;
//   }, []);
//   return overs;
// };

export const filterOvers2 = (overs) => {
  const filteredOvers = [];
  for (let i = 0; i < overs.length; i++) {
    const currentBall = overs[i];
    if (currentBall.isw && i > 0) {
      const previousBall = overs[i - 1];
      if (previousBall.ocn === currentBall.ocn) {
        filteredOvers.pop(); 
      }
    }
    filteredOvers.push(currentBall);
  }
  return filteredOvers;
};

export const getCommentaryRun = (bty, isB, runs, wik) => {
  if (bty) {
    if (bty == BALL_TYPE_PANELTY_RUN) return STRING_NAME[runs] + " run on penalty run";
    if (wik) return <b>OUT!</b>;
    if (isB) return runs === 4 ? <b>FOUR!</b> : <b>SIX!</b>;
    if (bty === 1 && runs >= 0) return STRING_NAME[runs] + " run";
    if (Object.keys(OTHER_BALLS).includes(String(bty))) return STRING_NAME[runs] + " run on " + OTHER_BALLS_FULL_NAME[bty];
  }
};

export const getFullRuns = (data) => {
  if (data) {
    return Object.keys(OTHER_BALLS).includes(String(data.bty))
      ? (data.runs > 0 ? data.runs + "|" : "") +
      "" +
      OTHER_BALLS[data.bty]
      : data.wik
        ? "w"
        : data.runs
  }
};

export const getSocketFullRuns = (data) => {
  if (data) {
    return Object.keys(OTHER_BALLS).includes(String(data.ballType))
      ? (data.ballRun > 0 ? data.ballRun + "|" : "") +
      "" +
      OTHER_BALLS[data.ballType]
      : data.ballIsWicket
        ? "w"
        : data.ballRun
  }
};

export const getPlayerName = (sqt, playerId) => {
  if (sqt && playerId) {
    let playerName = sqt?.find((val) => {
      return val.pid === playerId;
    });
    return playerName?.pn;
  }
};

export const convertTime = (time) => {
  if (!time) {
    return "";
  }
  const timeString = time;
  const [hours, minutes] = timeString.split(":").map(Number);
  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedTimeString = `${formattedHours}:${formattedMinutes} ${ampm}`;
  return formattedTimeString; // Output: "04:00 AM"
};

export const convertDateTimeUTCToLocal = (datetime) => {
  if (!datetime) {
    return "";
  }
  const localDate = moment.utc(datetime, "YYYY/MM/DD").local().format("DD/MM/YYYY");
  const localTime = moment.utc(datetime).local().format("hh:mm A");
  return { localDate, localTime };
}

export const getMatchType = (matchType) => {
  matchType = Number(matchType)
  if (matchType === 2 || matchType === 3) return LIVE;
  if (matchType === 1) return UPCOMMING;
  if (matchType === 4) return RESULT;
};

export const teamRunner = (market, teamId, teamName) => {
  if(market?.length > 0){
   const runnerById = market.find((item) => item.tid == teamId);
   const runnerByName = market.find((item) => String(item.rn).toLowerCase() === String(teamName).toLowerCase());
   const runner = runnerById || runnerByName
   return runner
  }
}

export const teamRunnerSocket = (market, teamId, teamName) => {
  if(market?.length > 0){
   const runnerById = (teamId && market) && market.find((item) => item.teamId == teamId);
   const runnerByName = (teamName && market) && market.find((item) => String(item.runner).toLowerCase() === String(teamName).toLowerCase());
   const runner = runnerById || runnerByName
   return runner
  }
}

export const getMarketType = (status) => {
  switch (status) {
    case 1:
      return "MARKET";
    case 2:
      return "BOOKMAKERS";
    case 3:
      return "MANUAL ODDS";
    case 4:
      return "FANCY";
    case 5:
      return "LINE MARKET";
    case 6:
      return "METER PARI";
    case 7:
      return "SPORT BOOK";
    default:
      return "";
  }
};