import React from "react";
import FullScoreMainSection from "../Components/FullScoreMainSection";
import FullScoreLayout from "../Layouts/FullScoreLayout";

const FullScore = () => {
  return (
    <FullScoreLayout>
      <FullScoreMainSection />
    </FullScoreLayout>
  );
};

export default FullScore;