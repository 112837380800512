import { useEffect, useState } from "react";
import { convertDateTimeUTCToLocal, getMatchDetails, getMatchType } from "../utilities/functions";
import _, { isEqual } from "lodash";

const FullScoreContent = ({ scoreData, runnerData, inProgress, isChase }) => {
  const [isData, setIsData] = useState(true);
  const [isCommentary, setIsCommentary] = useState(false);
  const [percentageLeft, setPercentageLeft] = useState(null);
  const [percentageRight, setPercentageRight] = useState(null);
  const [percentageDraw, setPercentageDraw] = useState(null);
  const [team1Score, setTeam1Score] = useState({});
  const [team2Score, setTeam2Score] = useState({});
  const [isTestMatch, setIsTestMatch] = useState(false);
  const [currentRunEvent, setCurrentRunEvent] = useState(null);
  const [overNo, setOverNo] = useState(null);
  const [animationClass, setAnimationClass] = useState("");
  const [currentStyle, setCurrentStyle] = useState(null);
  const [currentOver, setCurrentOver] = useState([]);
  document.title = (scoreData?.t1n && scoreData?.t2n) ? `${scoreData.t1n} vs ${scoreData.t2n}` : "loading...";
  
  const team1crr = scoreData?.scot === scoreData?.t1sn && scoreData?.crr
  const team2crr = scoreData?.scot === scoreData?.t2sn && scoreData?.crr

  useEffect(()=>{
    if(scoreData) {
      setPercentageLeft(parseFloat(scoreData?.tpp1));
      setPercentageRight(parseFloat(scoreData?.tpp2));
      setPercentageDraw(null);
      if(runnerData?.drawRunner?.runner === "The Draw" && scoreData?.com == "Test Matches" && (parseFloat(scoreData?.tpp1) + parseFloat(scoreData?.tpp2)) < 100){
        const draw = 100 - (parseFloat(scoreData?.tpp1) + parseFloat(scoreData?.tpp2));
        setPercentageDraw(draw);
      } else if((parseFloat(scoreData?.tpp1) && parseFloat(scoreData?.tpp2)) && (parseFloat(scoreData?.tpp1) + parseFloat(scoreData?.tpp2)) < 100){
        const draw = 100 - (parseFloat(scoreData?.tpp1) + parseFloat(scoreData?.tpp2));
        if(parseFloat(scoreData?.tpp1) > parseFloat(scoreData?.tpp2)){
          setPercentageLeft(parseFloat(scoreData?.tpp1) + draw)
        } else {
          setPercentageRight(parseFloat(scoreData?.tpp2) + draw)
        }
      }
    }
  },[scoreData?.tpp1, scoreData?.tpp2, runnerData?.drawRunner])


  useEffect(()=>{
    if(scoreData?.cst === 4 && (scoreData?.t3Id || scoreData?.t4Id)){
      setIsTestMatch(true)
      setTeam1Score(getMatchDetails(scoreData?.t3MatchDetails));
      setTeam2Score(getMatchDetails(scoreData?.t4MatchDetails));
    }
  },[scoreData])

  useEffect(()=>{
    if(scoreData?.tsi?.length > 0) {
    const oldInnings = scoreData?.tsi.find((i)=>i.inning===1)
    if(oldInnings){
      setIsTestMatch(true)
      setTeam1Score(getMatchDetails(oldInnings?.t1s));
      setTeam2Score(getMatchDetails(oldInnings?.t2s));
    }
    } else {
      setIsTestMatch(false);
    }
  },[scoreData?.tsi])

  useEffect(()=>{
    if(!scoreData?.t1n && !scoreData?.t2n && !scoreData?.com){
      setIsData(false);
    } else {
      setIsData(true);
    }
  },[scoreData])

  useEffect(()=>{
    if(!parseInt(scoreData?.t1MatchDetails?.runs) && !parseInt(scoreData?.t1MatchDetails?.wickets) && !parseInt(scoreData?.t1MatchDetails?.overs) && !parseInt(scoreData?.t2MatchDetails?.runs) && !parseInt(scoreData?.t2MatchDetails?.wickets) && !parseInt(scoreData?.t2MatchDetails?.overs)){
      setIsCommentary(true)
    } else {
      setIsCommentary(false)
    }
  },[scoreData])

  useEffect(() => {
    if (scoreData?.currOver?.["currentBall"]) {
      const { actualRun, ocn, isb, isw, bty } = scoreData.currOver.currentBall;
      const currentBall = scoreData?.currOver?.["currentBall"] || {};
      const currOverFromCurrMatch = Object.values(scoreData.currOver || {}).pop() || [];
      const status = scoreData?.dis || scoreData?.win;
      if (status && status != 4 && status != 6 && status !== "Wicket") {
        const overCount = bty != 0 ? ocn : null;
        setCurrentRunEvent(status);
        setCurrentStyle(null);
        setOverNo(overCount);
      } else if (
        !isEqual(currentOver, currOverFromCurrMatch) && (currentBall.isb || currentBall.isw) && bty != 0
      ) {
        const runData = isb ? actualRun : isw ? "Wicket" : null;
        setCurrentRunEvent(runData);
        setCurrentStyle({ backgroundColor: isw ? scoreData.bowlingTeam.bgColor : isb ? scoreData.battingTeam.bgColor : null, color: isw ? scoreData.bowlingTeam.borderColor : isb ? scoreData.battingTeam.borderColor : null });
        setOverNo(ocn);
      }
      setCurrentOver(currOverFromCurrMatch);
      setAnimationClass("score-animation");
      setTimeout(() => {
        setAnimationClass("");
        setCurrentStyle(null);
        setCurrentRunEvent(null); 
        setOverNo(null);
      }, 6000);
    }
  }, [scoreData?.currOver?.currentBall, scoreData?.dis, scoreData?.win]);

  return (
    isData ? (
      <div class="tab-content">
        <div id="live_match" class="tab-pane fade in show active">
          <div class="card card-shadow p-0">
            <div class=" p-0">
              <div className="score-card score-card-lg d-md-flex p-0" style={{borderTopLeftRadius: "0px"}}>
                <div className="flex-grow-1 pb-05">
                  <div className="score-card-header mb-05">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                    <strong className="eventName">{scoreData?.t1n} vs {scoreData?.t2n}</strong>
                    <strong className="mx-2 date">{convertDateTimeUTCToLocal(scoreData?.utc).localDate}{" "}
                    {convertDateTimeUTCToLocal(scoreData?.utc).localTime}</strong></div>
                    {/* <strong className="match-status">
                      {getMatchType(scoreData?.cst)}
                    </strong> */}
                    <span className="tournament">{scoreData?.com}</span>
                  </div>
                    {/* <span className="live-team-name">
                      {scoreData?.com}, {" "}
                      {convertDateTimeUTCToLocal(scoreData?.utc).localDate} {" "}
                      {convertDateTimeUTCToLocal(scoreData?.utc).localTime},{" "}
                      {scoreData?.t1n} vs {scoreData?.t2n}
                    </span> */}
                    {/* <div className="live-team-name d-flex justify-content-between">
                      <strong className="eventName">{scoreData?.t1n} vs {scoreData?.t2n}, {" "}
                      {convertDateTimeUTCToLocal(scoreData?.utc).localDate} {" "}
                      {convertDateTimeUTCToLocal(scoreData?.utc).localTime} {" "}</strong>
                    </div> */}
                      {/* <span className="tournament">{scoreData?.com}</span> */}
                    <strong className="text-red px-10">
                      {getMatchType(scoreData?.cst)}
                    </strong>
                  </div>
                  <div className="score-card-body px-10">
                    <div className="country-info">
                      <div className="flag-avatar d-flex flex-column align-items-center">
                        <figure>
                          <img
                            src={scoreData?.t1im || "/assets/images/flag.png"}
                            alt
                            onError={(e) => { e.target.src = "/assets/images/flag.png"; }}
                          />
                        </figure>
                        {/* <span className="country-name">{scoreData?.t1n}</span> */}
                      </div>
                      <div className="score-update ml-10 bungee-regular">
                        <h5>
                          {scoreData?.t1MatchDetails?.runs}/
                          {scoreData?.t1MatchDetails?.wickets}
                        </h5>
                        <p className="text-muted">
                          {parseFloat(scoreData?.t1MatchDetails?.overs).toFixed(
                            1
                          )}{" "}
                          ov
                        </p>
                      </div>
                    {isTestMatch ?
                      <div className="score-update-1 ml-10 bungee-regular">
                        <h6>
                          {team1Score?.runs}/
                          {team1Score?.wickets}
                        </h6>
                        <p className="text-muted-1">
                          {parseFloat(team1Score?.overs).toFixed(
                            1
                          )}{" "}
                          ov
                        </p>
                      </div>
                    : null}
                      {(inProgress && parseFloat(team1crr)) ? <div className="mx-5 mt-1 d-flex align-items-center">
                        <p className="mr-3">
                          Current RR <br/> <span className="team1-crr">{team1crr}</span>
                        </p>
                        {(parseFloat(scoreData?.rrr) && isChase) ? <p>
                          Required RR <br/> <span className="team1-crr">{scoreData?.rrr}</span>
                        </p> : null}
                      </div> : null}
                    </div>
                    {/* {scoreData?.cst === 4?  */}
                    <div class="score-update m-0 text-center">
                     <div className="d-flex flex-column justify-content-center align-items-center">
                      {(scoreData?.cst === 4 && scoreData?.res) &&  
                        <div>
                          <img src={"/assets/images/trophy1.png"} style={{ width:"25px", alignItems:"center"}}/>
                        </div>
                      }
                      {(scoreData?.cst === 4 && scoreData?.res) ? (
                        <strong className="result-rmk">{scoreData.res}</strong>
                      ) :(scoreData?.cst === 4 && isCommentary) ? (
                        <strong>Commentary not available</strong>
                      ) : scoreData?.cst === 4 ? (
                        <strong>Winner not available</strong>
                      ) : <strong>{scoreData?.loc}</strong>}
                     </div>
                    </div> 
                    {/* : null} */}
                    <div className="country-info flex-row-reverse">
                      <div className="flag-avatar ml-10 d-flex flex-column align-items-center">
                        <figure>
                          <img
                            src={scoreData?.t2im || "/assets/images/flag.png"}
                            alt
                            onError={(e) => { e.target.src = "/assets/images/flag.png"; }}
                          />
                        </figure>
                        {/* <span className="country-name">{scoreData?.t2n}</span> */}
                      </div>
                      <div className="score-update bungee-regular">
                        <h5>
                          {scoreData?.t2MatchDetails?.runs}/
                          {scoreData?.t2MatchDetails?.wickets}
                        </h5>
                        <p className="text-muted">
                          {parseFloat(scoreData?.t2MatchDetails?.overs).toFixed(
                            1
                          )}{" "}
                          ov
                        </p>
                      </div>
                    {isTestMatch ?
                      <div className="score-update-1 bungee-regular">
                        <h6>
                          {team2Score?.runs}/
                          {team2Score?.wickets}
                        </h6>
                        <p className="text-muted-1">
                          {parseFloat(team2Score?.overs).toFixed(
                            1
                          )}{" "}
                          ov
                        </p>
                      </div>
                    : null}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mx-2 px-10">
                    <div className="d-flex align-items-center">
                      <span class="team">{scoreData?.t1n}</span>
                      {(scoreData?.t1id && scoreData?.ballid) && scoreData?.t1id == scoreData?.ballid && <img className="bowl-logo" src={"/assets/images/bowling-old.png"} alt="" />}
                      {(scoreData?.t1id && scoreData?.batid) && scoreData?.t1id == scoreData?.batid && <img className="bat-logo" src={"/images/bat.png"} alt="" />}
                    </div>
                    <div className="d-flex align-items-center">
                      {(scoreData?.t2id && scoreData?.ballid) && scoreData?.t2id == scoreData?.ballid && <img className="bowl-logo" src={"/assets/images/bowling-old.png"} alt="" />}
                      {(scoreData?.t2id && scoreData?.batid) && scoreData?.t2id == scoreData?.batid && <img className="bat-logo" src={"/images/bat.png"} alt="" />}
                      <span class="team">{scoreData?.t2n}</span>
                    </div>
                  </div>
                  {(runnerData?.team1Runner && runnerData?.team2Runner && scoreData?.cst != 4 && (runnerData?.team1Runner?.backPrice || runnerData?.team1Runner?.layPrice || runnerData?.team2Runner?.backPrice || runnerData?.team2Runner?.layPrice)) ? (
                  <div className="d-md-flex align-items-center justify-content-between px-10 mt-2">
                    {/* <div className="d-flex align-items-center my-2"> */}
                      {/* <div className="runner-team mr-2">{scoreData?.t1n}</div> */}
                      <div className="d-flex align-items-center mx-2">
                        <div className="back-price px-2">
                          {runnerData?.team1Runner?.backPrice}
                        </div>
                        <div className="lay-price px-2">
                          {runnerData?.team1Runner?.layPrice}
                        </div>
                      </div>
                    {/* </div> */}
                    {runnerData?.drawRunner && (
                      <div className="d-flex align-items-center">
                        <div className="runner-team">Draw</div>
                        <div className="d-flex align-items-center mx-2">
                          <div className="back-price px-2">
                            {runnerData?.drawRunner?.backPrice}
                          </div>
                          <div className="lay-price px-2">
                            {runnerData?.drawRunner?.layPrice}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <div className="d-flex align-items-center my-2"> */}
                      {/* <div className="runner-team mr-2">{scoreData?.t2n}</div> */}
                      <div className="d-flex align-items-center mx-2">
                        <div className="back-price px-2">
                          {runnerData?.team2Runner?.backPrice}
                        </div>
                        <div className="lay-price px-2">
                          {runnerData?.team2Runner?.layPrice}
                        </div>
                      </div>
                    </div>
                  // </div>
                ) : null }
                  {(((percentageLeft && percentageRight) || runnerData?.drawRunner?.runner == "The Draw") && scoreData?.cst != 4) ? (
                  <div className="progress-bars-2 w-100 mt-2 px-10">
                    <div className="progress-title d-flex flex-column align-items-center">
                      <div className="progress">
                        <div
                          style={{
                            width: `${percentageLeft}%`,
                            backgroundColor:
                              percentageLeft > percentageRight
                                ? "green"
                                : "red",
                          }}
                        ></div>
                        {percentageDraw && 
                        <div
                          style={{
                            width: `${percentageDraw}%`,
                            backgroundColor:"gray",
                          }}
                        ></div>}
                        <div
                          style={{
                            width: `${percentageRight}%`,
                            backgroundColor:
                              percentageRight > percentageLeft
                                ? "green"
                                : "red",
                          }}
                        ></div>
                      </div>
                      <div className="d-flex justify-content-between progress-content">
                        <span className="progress-amount">
                          ({percentageLeft}%)
                        </span>
                        {percentageDraw ? <span className="progress-amount">Draw {percentageDraw}%</span> : <span className="progress-amount">Realtime Win %</span>}
                        <span className="progress-amount">
                          ({percentageRight}%)
                        </span>
                      </div>
                    </div>
                  </div>
                ) : null}
                </div>
                {/* {inProgress && <div className="card-aside text-left px-20">
                  {(parseFloat(team2crr)) ? <>
                  <p>
                    Current RR<span>{team2crr}</span>
                  </p>
                  {(parseFloat(scoreData?.rrr) && isChase) ? <p>
                    Required RR<span>{scoreData?.rrr}</span>
                  </p> : null} 
                </> : null}
                </div>} */}
                {inProgress && <div className="card-aside text-center" style={{ backgroundColor: currentStyle?.backgroundColor }}>
                {overNo ? <span className="overCount mx-2 my-2">{overNo} ov</span> : null}
                {currentRunEvent ? (
                  <div className={`d-flex align-items-center justify-content-center animation-height score-font bungee-regular ${animationClass}`} style={{ color : currentStyle?.color }}>
                    {currentRunEvent === 4 ? <span>FOUR</span> : currentRunEvent === 6 ? <span>SIX</span> : <span>{currentRunEvent}</span>}
                  </div>
                ) : null }
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div class="card card-shadow p-0">
          <div className="score-card-inner flex-grow-1 px-20 py-20 text-center">
            <strong> There are no matches now </strong>
          </div>
      </div>
    )
  );
};

export default FullScoreContent;
