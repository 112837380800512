import { useEffect, useState } from "react";
import MatchContent from "./MatchContent";
import { LIVE, RESULT, UPCOMMING } from "../utilities/const";
import { Link } from "react-router-dom";
import axiosInstance from "../Features/axios";

const MatchMainSection = () => {
  document.title = 'Home';
  const [matchType, setMatchType] = useState("LIVE");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-TMN4CW60YD`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-TMN4CW60YD');
  }, []);

  // useEffect(() => {
  //   setLoading(true);
  // }, [matchType]);

  return (
    <div class="col-lg-9">
      <section class="live-matches pt-0">
        <div class="card card-shadow">
          <ul class="nav nav-tabs mb-0">
            <li class="active">
              <Link
                data-toggle="tab"
                to={"/#"}
                class="active"
                onClick={() => setMatchType(LIVE)}
              >
                Live
              </Link>
            </li>
            <li>
              <Link data-toggle="tab" onClick={() => setMatchType(UPCOMMING)}>
                Upcoming
              </Link>
            </li>
            <li>
              <Link data-toggle="tab" onClick={() => setMatchType(RESULT)}>
                Result
              </Link>
            </li>
          </ul>
        </div>
        <MatchContent
          matchType={matchType}
          loading={loading}
          setLoading={setLoading}
        />
      </section>
    </div>
  );
};

export default MatchMainSection;
