import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../Features/axios";
import { useWindowSize } from "@uidotdev/usehooks";
import MainHeaderSkeleton from "../Skeleton/MainHeaderSkeleton";
import socket from "../../Features/socket";
import { CONNECT, SEND_NOTIFICATION, USER_DATA_KEY, USER_LOGGED_IN } from "../../utilities/const";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./mainHeader.css";
import { useAppContext } from "../../Context/AppContext";

const MainHeader = () => {
  const windowSize = useWindowSize();

  const [menuItem, setMenuItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mobileView, setMobileView] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [token, setToken] = useState("");
  const [userData, setUserData] = useState({});
  const [notificationList, setNotificationList] = useState([])
  const [notificationCount, setNotificationCount] = useState("")
  const [latestNotification, setLatestNotification] = useState(null);
  const { cmsData } = useAppContext();

  const navigate = useNavigate();
  useEffect(() => {
    if (cmsData?.menuItems?.[0]) {
      setMenuItem(cmsData?.menuItems[0]?.menuItem);
      setLoading(false);
    }
  }, [cmsData?.menuItems]);

  useEffect(() => {
    if (windowSize.width < 991) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, [windowSize]);

  const toggleMenu = () => {
    if (mobileView) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  useEffect(()=>{
    const tokenVal = localStorage.getItem("accessToken");
    const userDataVal = localStorage.getItem(USER_DATA_KEY);
    if(userDataVal){
      setUserData(JSON.parse(userDataVal))
    }
    setToken(tokenVal || "")
  }, [])

  const loadNotification = async (clientId) => {
    try {
      const response = await axiosInstance.post(
        `/api/notificationByClient`,
        { 
          clientId: clientId,
          page: 1
        }
      );
      const sortedNotifications = response?.data?.result?.notData?.sort((a, b) => {
        return b.notificationId - a.notificationId;
      });
      setNotificationList(sortedNotifications)
      setNotificationCount(response?.data?.result?.unreadNot)
    } catch (error) {
      console.error("Notification By Client failed", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    if(userData.clientId) {
      loadNotification(userData.clientId)
    }
  },[userData?.clientId])

  const onNotificationRead = async (record) => {
    if (!record?.isRead) {
      try {
        const response = await axiosInstance.post(`/api/markRead`, {
          clientId: userData.clientId,
          notificationId: [record?.notificationId],
        });
        if (response?.data?.status === 200) {
          loadNotification(userData.clientId);
        }
      } catch (error) {
        console.error("Notification By Client failed", error);
      } finally {
        setLoading(false);
        // toggleMenu();
      }
    }
  };

  const showNotification = (notificationData) => {
    toast.info(
      <div>
        <h4>{notificationData?.title}</h4>
        <p>{notificationData?.description}</p>
        {notificationData?.url && (
          <a
            href={notificationData.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            More Info
          </a>
        )}
      </div>
    );
  };

  const configSocket = () => {
    if (token) {
      socket?.emit(USER_LOGGED_IN);
    }
    socket?.on(SEND_NOTIFICATION, (data) => {
      setLatestNotification(data);

      if(userData.clientId){
        loadNotification(userData.clientId)
      }
    });
  };

  useEffect(() => {
    if (socket?.connected) {
      configSocket();
    } else {
      socket.on(CONNECT, () => {
        configSocket();
      });
    }
  }, [token]);

  useEffect(() => {
    if (latestNotification) {
      showNotification(latestNotification);
    }
  }, [latestNotification]);

  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      const headers = {
        Authorization: `${token}`,
      };
      const response = await axiosInstance.post(
        `/api/logoutClient`,
        {},
        { headers }
      );
      if (response.status === 200) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem(USER_DATA_KEY);
        if (window.location.pathname === '/') {
          window.location.reload();
        } else {
          navigate("/");
        }
      } else {
        console.error("Logout failed with status", response.status);
      }
    } catch (error) {
      console.error("Logout failed", error);
    } finally {
      setLoading(false);
      // toggleMenu()
    }
  };

  return (
    <section className="main-header">
      <div className="container">
        <nav className="navbar">
          <div className="nav-menu">
            <button className="mobile-nav-toggler" onClick={toggleMenu}>
              <i className="fas fa-bars"></i>
            </button>
            <ul style={{ display: isMenuOpen ? "block" : "none" }}>
              {loading ? (
                <MainHeaderSkeleton />
              ) : (
              menuItem &&
                menuItem?.map((data) => (
                  <li
                    key={data.menuItemId}
                    className={data.menuItems ? "has-submenu" : ""}
                  >
                    <Link to={data.pageDetails.alias} onClick={toggleMenu}>
                      {data.menuItem}
                      <i
                        class={data.menuItems ? "fas fa-angle-down pl-10" : ""}
                      ></i>
                    </Link>
                    {data.menuItems ? (
                      <ul className="sub-menu">
                        {data.menuItems.map((data) => (
                          <li
                            key={data.menuItemId}
                            className="has-more-menu-child"
                          >
                            <Link
                              to={data.pageDetails.alias}
                              onClick={toggleMenu}
                              className={
                                data.menuItems
                                  ? !mobileView
                                    ? "d-flex justify-content-between"
                                    : ""
                                  : ""
                              }
                            >
                              {data.menuItem}
                              <i
                                class={
                                  data.menuItems
                                    ? mobileView
                                      ? "fas fa-angle-down pl-10"
                                      : "fas fa-angle-right"
                                    : ""
                                }
                              ></i>
                            </Link>
                            {data.menuItems ? (
                              <ul className="more-sub-menu">
                                {data.menuItems.map((data) => (
                                  <li>
                                    <Link
                                      to={data.pageDetails.alias}
                                      onClick={toggleMenu}
                                    >
                                      {data.menuItem}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                ))
              )
            }
            </ul>
          </div>
          <div className="nav-menu">
          {token ? (
              <li style={{ marginRight: notificationCount > 0 ? "30px" : "10px" }}>
                 <div className="dropdown">
                  <button
                    type="button"
                    id="dropdownNotification"
                    className="d-flex align-items-center"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ background: "none", border: "none" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#fff"
                      className="bi bi-bell-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                    </svg>
                    {notificationCount > 0 && (
                      <span className="notification-count">
                        {notificationCount}
                      </span>
                    )}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownNotification"
                  >
                    {notificationList?.filter((i)=>i.title !== null).length > 0 ? (
                      notificationList?.filter((i)=>i.title !== null).map((item, index) => (
                        <div
                          key={index}
                          className="dropdown-item d-flex justify-content-between align-items-center"
                          // to={item?.url}
                          onClick={() => onNotificationRead(item)}
                        >
                          <span>{item.title}</span>
                          {item.isRead === false && (
                            <span className="notification-dot"></span>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="dropdown-item text-center">No notifications</div>
                    )}
                  </div>
                </div>
              </li>
            ) : null}
            <li>
              {token ? (
                <div className="dropdown">
                  <button
                    type="button"
                    className="dropdown-profile d-flex align-items-center"
                    id="dropdownProfile"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ background: "none", border: "none" }}
                  >  
                     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                        <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                     </svg> 
                    {userData?.fullName ? (
                      <span className="text-white ml-2 profile-name">
                        {userData.fullName}
                      </span>
                    ) : userData?.emailId ? (
                      <span className="text-white mx-2 profile-name">{userData.emailId}</span>
                    ) : (
                      ""
                    )}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownProfile"
                  >
                    <Link
                      to="/profile"
                      data-toggle="tab"
                      className="dropdown-item d-flex align-items-center"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                        <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                      </svg> 
                      <span className="ml-3">Profile </span>
                    </Link>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      onClick={handleLogout}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                        <path d="M7.5 1v7h1V1z"/>
                        <path d="M3 8.812a5 5 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812"/>
                      </svg>
                      <span className="ml-3">Logout </span>
                    </Link>
                  </div>
                </div>
              ) : null}
            </li>
            <li>
              {!token ? (
                <Link to="/login" className="login-link" onClick={toggleMenu}>
                  Login
                </Link>
              ) : null}
            </li>
            {/* <li>
              {userData?.fullName ? 
                <span className="text-white">Welcome : {userData.fullName}</span>
              : userData?.emailId ? 
                <span className="text-white">{userData.emailId}</span>
              :  ""}
            </li>
            <li>
              {token ? (
                <Link className="logout-link" onClick={handleLogout}>
                  Logout
                </Link>
              ) : (
                <Link to="/login" className="login-link" onClick={toggleMenu}>
                  Login
                </Link>
              )}
            </li> */}
          </div>
          <div className="header-search">
            <form action="#">
              <input type="text" placeholder="Search here" required="" />
              <button type="submit">
                <i className="fas fa-search"></i>
              </button>
            </form>
          </div>
        </nav>
      </div>
      <ToastContainer />
    </section>
  );
};

export default MainHeader;
