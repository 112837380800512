import FullScoreSidebarContent from "./FullScoreSidebarContent";

const FullscoreSideBar = () => {
  return (
    <div class="col-lg-3">
      <aside class="sidebar right-sidebar">
        <FullScoreSidebarContent />
      </aside>
    </div>
  )
}
export default FullscoreSideBar;