import { useEffect, useState } from "react";
import { CONNECT, EVENT_DATA, EVENT_RUNNER_DATA, LIVE, RESULT, UPCOMMING } from "../utilities/const";
import { convertDateTimeUTCToLocal, getMatchDetails, getOvers, teamRunnerSocket } from "../utilities/functions";
import socket from "../Features/socket";
import { convertTime } from "../utilities/functions";
import { Link, useNavigate } from "react-router-dom";
import CountdownTimer from "./common/CountDownTimer";
import _, { isEqual } from "lodash";

const MatchContentCard = ({ cardData, mt, newsContent }) => {
    const navigate = useNavigate();

    const [scoreData, setScoreData] = useState({
        ...cardData,
        url: cardData?.en.replace(/\s+/g, '-').toLowerCase() + "-" + cardData?.com.replace(/\s+/g, '-').toLowerCase() + "-" + convertDateTimeUTCToLocal(cardData?.utc).localDate.replace(/\//g, '-').toLowerCase() + "-" + cardData?.te1n.replace(/\s+/g, '-').toLowerCase() + "-vs-" + cardData?.te2n.replace(/\s+/g, '-').toLowerCase(),
        t1MatchDetails: getMatchDetails(cardData?.t1s),
        t2MatchDetails: getMatchDetails(cardData?.t2s),
        t1id: cardData?.t1id,
        t2id: cardData?.t2id,
        t1n: cardData?.te1n,
        t2n: cardData?.te2n,
        dis: cardData?.dis,
        // team1Runner: teamRunner(cardData?.mr, cardData?.t1id, cardData?.te1n),
        // team2Runner: teamRunner(cardData?.mr, cardData?.t2id, cardData?.te2n),
        // drawRunner: cardData?.mr?.length > 0 ? cardData?.mr?.find((item)=> item.runner == "The Draw") : null,
    })
    const [team1Score, setTeam1Score] = useState({});
    const [team2Score, setTeam2Score] = useState({});
    const [isTestMatch, setIsTestMatch] = useState(false);
    const [isShow, setIsShow] = useState(true);
    const [isCommentary, setIsCommentary] = useState(false);
    const [runnerData, setRunnerData] = useState(null);
    const [marketRunnerData, setMarketRunnerData] = useState(null);
    const [currentRunEvent, setCurrentRunEvent] = useState(null);
    const [overNo, setOverNo] = useState(null);
    const [animationClass, setAnimationClass] = useState("");
    const [currentStyle, setCurrentStyle] = useState(null);
    const [currentOver, setCurrentOver] = useState([]);

    const handleCSTType = (eventData) => {
        const allowedCst = [
            { type: LIVE, cst: [2, 3] },
            { type: UPCOMMING, cst: [1] },
            { type: RESULT, cst: [4] },
        ];

        const allowedCstForType = allowedCst.find(item => item.type === mt)?.cst;
        setIsShow(allowedCstForType.includes(Number(eventData?.cm.cst)));
    };

    useEffect(() => {
        convertTime(scoreData?.et)
    })

    useEffect(()=>{
        if(cardData?.tsi?.length > 0) {
        const oldInnings = cardData?.tsi.find((i)=>i.inning === 1)
        if(oldInnings){
          setIsTestMatch(true)
          setTeam1Score(getMatchDetails(oldInnings?.t1s));
          setTeam2Score(getMatchDetails(oldInnings?.t2s));
        }
        }
    },[cardData?.tsi])

    const configSocket = () => {
        socket?.on(EVENT_DATA, (data) => {
            const eventData = data?.value;
            if (data?.eventId === scoreData?.eid && eventData) {
                const currOver = getOvers(eventData?.cm.scov, eventData?.cbb);
                handleCSTType(eventData);
                setScoreData({
                    ...scoreData,
                    t1MatchDetails: eventData?.cm?.t1s ? getMatchDetails(eventData?.cm?.t1s) : {},
                    t2MatchDetails: eventData?.cm?.t2s ? getMatchDetails(eventData?.cm?.t2s) : {},
                    rmk: eventData?.cm?.rmk || "",
                    crr: eventData?.cm?.crr || 0,
                    // loc: eventData?.cm?.loc || "",
                    currOver: currOver,
                    dis: eventData?.cm?.dis,
                    win: eventData?.cm?.win,
                    ics: eventData?.cm?.ics,
                    battingTeam: eventData?.cm?.scot == eventData?.cm?.t1sn ? { bgColor: eventData?.cm?.t1bg, borderColor: eventData?.cm?.t1co } : { bgColor: eventData?.cm?.t2bg, borderColor: eventData?.cm?.t2co },
                    bowlingTeam: eventData?.cm?.scot == eventData?.cm?.t2sn ? { bgColor: eventData?.cm?.t1bg, borderColor: eventData?.cm?.t1co } : { bgColor: eventData?.cm?.t2bg, borderColor: eventData?.cm?.t2co },
                    // team1Runner: teamRunner(eventData?.mr, eventData?.cm?.t1id, eventData?.cm?.t1n),
                    // team2Runner: teamRunner(eventData?.mr, eventData?.cm?.t2id, eventData?.cm?.t2n),
                    // drawRunner: eventData?.mr?.length > 0 ? eventData?.mr?.find((item)=> item.runner == "The Draw") : null,
                })
                if (eventData?.cm?.tsi.length > 0) {
                    const oldInnings = eventData?.cm?.tsi.find((i) => i.inning === 1);
                    if (oldInnings) {
                      setIsTestMatch(true);
                      setTeam1Score(getMatchDetails(oldInnings?.t1s));
                      setTeam2Score(getMatchDetails(oldInnings?.t2s));
                    }
                }
            }
        });
    }

  useEffect(() => {
    if (scoreData?.currOver?.["currentBall"]) {
      const { actualRun, ocn, isb, isw, bty } = scoreData.currOver.currentBall;
      const currentBall = scoreData?.currOver?.["currentBall"] || {};
      const currOverFromCurrMatch = Object.values(scoreData.currOver || {}).pop() || [];
      const status = scoreData?.dis || scoreData?.win;
      if (status && status != 4 && status != 6 && status !== "Wicket") {
        const overCount = bty != 0 ? ocn : null;
        setCurrentRunEvent(status);
        setCurrentStyle(null);
        setOverNo(overCount);
      } else if (
        !isEqual(currentOver, currOverFromCurrMatch) && (currentBall.isb || currentBall.isw) && bty != 0
      ) {
        const runData = isb ? actualRun : isw ? "Wicket" : null;
        setCurrentRunEvent(runData);
        setCurrentStyle({ backgroundColor: isw ? scoreData.bowlingTeam.bgColor : isb ? scoreData.battingTeam.bgColor : null, color: isw ? scoreData.bowlingTeam.borderColor : isb ? scoreData.battingTeam.borderColor : null });
        setOverNo(ocn);
      }
      setCurrentOver(currOverFromCurrMatch);
      setAnimationClass("score-animation");
      setTimeout(() => {
        setAnimationClass("");
        setCurrentStyle(null);
        setCurrentRunEvent(null); 
        setOverNo(null);
      }, 6000);
    }
  }, [scoreData?.currOver?.currentBall, scoreData?.dis, scoreData?.win]);

    const runnerSocket = () => {
      socket?.on(EVENT_RUNNER_DATA, (data) => {        
        const eventRunnerData = data?.value;
        if (data?.eventId === scoreData?.eid && eventRunnerData) {
          const marketRunner = eventRunnerData[0]?.runners;
          setMarketRunnerData(marketRunner)
        }
      });
    }

    useEffect(()=>{
      const team1runner = teamRunnerSocket(marketRunnerData, scoreData?.t1id, scoreData?.t1n);
      const team2runner = teamRunnerSocket(marketRunnerData, scoreData?.t2id, scoreData?.t2n);
      const draw = marketRunnerData?.length > 0 ? marketRunnerData.find((item)=> item.runner == "The Draw") : null;
      setRunnerData({
        team1Runner: team1runner,
        team2Runner: team2runner,
        drawRunner: draw,
      })
    },[marketRunnerData, scoreData?.t1id, scoreData?.t2id, scoreData?.t1n, scoreData?.t2n]);

    useEffect(() => {
        if (socket) {
            if (socket?.connected) {
                configSocket()
            } else {
                socket?.on(CONNECT, () => {
                    configSocket()
                });
            }
        }
    }, [scoreData?.eid])

    useEffect(() => {
      if (socket) {
          if (socket?.connected) {
            runnerSocket();
          } else {
              socket?.on(CONNECT, () => {
                runnerSocket();
              });
          }
      }
  }, [scoreData?.eid])

    useEffect(()=>{
        if(!parseInt(scoreData.t1MatchDetails?.runs) && !parseInt(scoreData.t1MatchDetails?.wickets) && !parseInt(scoreData.t1MatchDetails?.overs) && !parseInt(scoreData.t2MatchDetails?.runs) && !parseInt(scoreData.t2MatchDetails?.wickets) && !parseInt(scoreData.t2MatchDetails?.overs)){
            setIsCommentary(true)
        } else {
            setIsCommentary(false)
        }
    },[scoreData])

    return isShow && (
        <Link to={(!scoreData?.ics && scoreData?.cst == 4) ? "#" : `/full-score/${cardData?.eid}/${scoreData?.url}`} style={{ color: "inherit", cursor: !scoreData?.ics && scoreData?.cst == 4 ? "default" : "pointer" }}>
        <div class="card card-shadow p-0">
            <div class="score-card score-card-lg d-md-flex p-0" style={{borderTopLeftRadius: "0px"}}>
                <div class="flex-grow-1 pb-05">
                    <div class="score-card-header mb-05 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                        {/* {!newsContent && <strong class="text-red">{mt}</strong>} */}
                        <strong className="eventName">{scoreData.en}{/* {newsContent && <br />}  */}{/* {scoreData.te1n} vs {scoreData.te2n} */}</strong>
                        {mt==="RESULT" ? <strong className="mx-2 date">{convertDateTimeUTCToLocal(scoreData?.utc).localDate}{" "}
                        {convertDateTimeUTCToLocal(scoreData?.utc).localTime}</strong> : null}
                        </div>
                        <div><strong className="tournament">{scoreData.com}</strong></div>
                    </div>
                    <div class="score-card-body px-10">
                        <div class="country-info">
                            <div class="flag-avatar d-flex flex-column align-items-center">
                                <figure>
                                    <img src={scoreData?.te1i || "/assets/images/flag.png"} alt="" onError={(e) => { e.target.src = "/assets/images/flag.png"; }} />
                                </figure>
                                {/* <span class="country-name">{scoreData.te1n}</span> */}
                            </div>
                            <div class="score-update ml-10 bungee-regular">
                                <h5>{scoreData.t1MatchDetails?.runs}/{scoreData.t1MatchDetails?.wickets}</h5>
                                <p class="text-muted">{parseFloat(scoreData.t1MatchDetails?.overs).toFixed(1)} ov</p>
                            </div>
                            {isTestMatch ?
                            <div class="score-update-1 ml-10 bungee-regular">
                                <h6>{team1Score?.runs}/{team1Score?.wickets}</h6>
                                <p class="text-muted-1">{parseFloat(team1Score?.overs).toFixed(1)} ov</p>
                            </div>
                            : null}
                        </div>
                        <div class="score-update m-0 text-center">
                            {/* <p class="vs-circle">VS</p> */}
                            { mt==="RESULT" ? (
                             <div className="d-flex flex-column justify-content-center align-items-center">
                               { scoreData?.res &&  
                               <div>
                                 <img src={"/assets/images/trophy1.png"} style={{ width:"25px", alignItems:"center"}}/>
                               </div>
                                }
                               {scoreData?.res ? (
                                 <strong className="result-rmk">{scoreData.res}</strong>
                               ) : isCommentary ? (
                                 <strong>Commentary not available</strong>
                               ) : (
                                 <strong>Winner not available</strong>
                               )}
                               {/* <span className="date">{convertDateTimeUTCToLocal(scoreData?.utc).localDate} {convertDateTimeUTCToLocal(scoreData?.utc).localTime}</span> <br /> */}
                             </div>
                             ) : mt==="UPCOMING" ? (
                             <>
                               {/* <strong className="upcoming-rmk">{scoreData?.rmk}</strong> <br /> */}
                               <span className="date">{convertDateTimeUTCToLocal(scoreData?.utc).localDate} {convertDateTimeUTCToLocal(scoreData?.utc).localTime}</span> <br />
                               {/* <span className="team-css">{scoreData.te1n} vs {scoreData.te2n}</span> <br/> */}
                               <span className="location">{scoreData?.loc}</span>
                             </>
                            ) : (
                             <>
                               {/* <strong className="upcoming-rmk">{scoreData?.rmk}</strong> <br /> */}
                               <span className="date">{convertDateTimeUTCToLocal(scoreData?.utc).localDate} {convertDateTimeUTCToLocal(scoreData?.utc).localTime}</span> <br />
                               {/* <span className="team-css">{scoreData.te1n} vs {scoreData.te2n}</span> <br/> */}
                               <span className="location">{scoreData?.loc}</span>
                             </>
                            )
                           }
                        </div>
                        <div class="country-info flex-row-reverse">
                            <div class="flag-avatar ml-10 d-flex flex-column align-items-center">
                                <figure>
                                    <img src={scoreData?.te2i || "/assets/images/flag.png"} alt="" onError={(e) => { e.target.src = "/assets/images/flag.png"; }} />
                                </figure>
                                {/* <span class="country-name">{scoreData.te2n}</span> */}
                            </div>
                            <div class="score-update bungee-regular">
                                <h5>{scoreData.t2MatchDetails?.runs}/{scoreData.t2MatchDetails?.wickets}</h5>
                                <p class="text-muted">{parseFloat(scoreData.t2MatchDetails?.overs).toFixed(1)} ov</p>
                            </div>
                            {isTestMatch ?
                            <div class="score-update-1 bungee-regular">
                                <h6>{team2Score?.runs}/{team2Score?.wickets}</h6>
                                <p class="text-muted-1">{parseFloat(team2Score?.overs).toFixed(1)} ov</p>
                            </div>
                            : null}
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mx-2 px-10">
                      <div className="d-flex align-items-center">
                        <span class="team">{scoreData?.te1n}</span>
                        {(scoreData?.t1id && scoreData?.ballid) && scoreData?.t1id == scoreData?.ballid && <img className="bowl-logo" src={"/assets/images/bowling-old.png"} alt="" />}
                        {(scoreData?.t1id && scoreData?.batid) && scoreData?.t1id == scoreData?.batid && <img className="bat-logo" src={"/images/bat.png"} alt="" />}
                      </div>
                      <div className="d-flex align-items-center">
                        {(scoreData?.t2id && scoreData?.ballid) && scoreData?.t2id == scoreData?.ballid && <img className="bowl-logo" src={"/assets/images/bowling-old.png"} alt="" />}
                        {(scoreData?.t2id && scoreData?.batid) && scoreData?.t2id == scoreData?.batid && <img className="bat-logo" src={"/images/bat.png"} alt="" />}
                        <span class="team">{scoreData?.te2n}</span>
                      </div>
                    </div>
                {(runnerData?.team1Runner && runnerData?.team2Runner && mt !== "RESULT" && (runnerData?.team1Runner?.backPrice || runnerData?.team1Runner?.layPrice || runnerData?.team2Runner?.backPrice || runnerData?.team2Runner?.layPrice)) ? (
                  <div className="d-md-flex align-items-center justify-content-between px-10">
                    {/* <div className="d-flex align-items-center my-2"> */}
                      {/* <div className="runner-team mr-2">{runnerData?.team1Runner?.runner || scoreData?.te1n}</div> */}
                      <div className="d-flex align-items-center mx-2">
                        <div className="back-price px-2">
                          {runnerData?.team1Runner?.backPrice}
                        </div>
                        <div className="lay-price px-2">
                          {runnerData?.team1Runner?.layPrice}
                        </div>
                      </div>
                    {/* </div> */}
                    {/* {runnerData?.drawRunner && (
                      <div className="d-flex align-items-center">
                        <div className="runner-team">Draw</div>
                        <div className="d-flex align-items-center mx-2">
                          <div className="back-price px-2">
                            {runnerData?.drawRunner?.backPrice}
                          </div>
                          <div className="lay-price px-2">
                            {runnerData?.drawRunner?.layPrice}
                          </div>
                        </div>
                      </div>
                    )} */}
                    {(!newsContent && mt === "LIVE") &&  <div className="px-2 text-center"><strong>{(scoreData?.twonby && scoreData?.choseto) && `${scoreData.twonby} won the toss and chose to ${scoreData.choseto}`}</strong></div>}
                    {/* <div className="d-flex align-items-center my-2"> */}
                      {/* <div className="runner-team mr-2">{runnerData?.team2Runner?.runner || scoreData?.te2n}</div> */}
                      <div className="d-flex align-items-center mx-2">
                        <div className="back-price px-2">
                          {runnerData?.team2Runner?.backPrice}
                        </div>
                        <div className="lay-price px-2">
                          {runnerData?.team2Runner?.layPrice}
                        </div>
                      </div>
                    </div>
                  // </div>
                ) : null }
                  {/* {(!newsContent && mt === "LIVE") &&  <div className="px-2 text-center"><strong>{(scoreData?.twonby && scoreData?.choseto) && `${scoreData.twonby} won the toss and chose to ${scoreData.choseto}`}</strong></div>} */}
                    {/* <div class="floating-text text-14">{scoreData?.rmk}. CRR: {scoreData?.crr}</div> */}
                </div>
                { (!newsContent && mt === "LIVE") && 
                <div className="card-aside text-center" style={{ backgroundColor: currentStyle?.backgroundColor }}>
                {/* <div className="px-2"><strong>{(scoreData?.twonby && scoreData?.choseto) && `${scoreData.twonby} won the toss and chose to ${scoreData.choseto}`}</strong></div> */}
                {/* <hr className="horizontal-line" /> */}
                {overNo ? <span className="overCount mx-2">{overNo} ov</span> : null}
                {currentRunEvent ? (
                  <div className={`d-flex align-items-center justify-content-center animation-height score-font bungee-regular ${animationClass}`} style={{ color : currentStyle?.color }}>
                    {currentRunEvent === 4 ? <span>FOUR</span> : currentRunEvent === 6 ? <span>SIX</span> : <span>{currentRunEvent}</span>}
                  </div>
                ) : null }
                {/* <strong>{scoreData?.rmk}</strong> <br /> */}
                </div>
                }
                { (!newsContent && mt === "UPCOMING") &&
                <div class="card-aside text-center d-flex justify-content-center">
                 <CountdownTimer scoreData={scoreData} />
                </div>
                }
                {/* {!newsContent &&
                <div class="card-aside text-right px-20 py-20"> */}
                    {/* <Link to={"/full-score/" + cardData?.eid + "/" + scoreData?.url} class="cricnotch-btn btn-filled text-uppercase active">Live Score</Link> */}
                    {/* <Link to={"/#"} class="cricnotch-btn btn-filled text-uppercase">Series Home</Link> */}
                {/* </div>
                } */}
            </div>
        </div>
        </Link>
    )
}

export default MatchContentCard;