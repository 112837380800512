import React, { useEffect, useState } from "react";
import axiosInstance from "../Features/axios";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { USER_DATA_KEY } from "../utilities/const";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToast from "../Components/Reusables/CustomToast";

const SetPassword = () => {
  document.title = "SetPassword";
  const [validation, setValidation] = useState({
    values: {
      password: "",
      confirmPassword: "",
    },
    errors: {},
    touched: {},
  });
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});

  const navigate = useNavigate();

  useEffect(()=>{
    const userDataVal = localStorage.getItem(USER_DATA_KEY);
    if(userDataVal){
      setUserData(JSON.parse(userDataVal))
    }
  }, [])

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    let errors = {};
    const { password, confirmPassword } = validation.values;

    if (!password) errors.password = "Password is required";
    if (!confirmPassword)
      errors.confirmPassword = "Confirm Password is required";

    if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    setValidation((prevValidation) => ({
      ...prevValidation,
      errors,
    }));

    if (Object.keys(errors).length > 0) {
      return;
    }

    if (validation.values.password !== validation.values.confirmPassword) {
      setValidation((prevValidation) => ({
        ...prevValidation,
        errors: {
          ...prevValidation.errors,
          confirmPassword: "Passwords do not match",
        },
      }));
      return;
    }

    const payload = {
      password: validation.values.password,
      email: userData?.emailId,
    };

    try {
      const response = await axiosInstance.post(`/api/setPassword`, payload);
      if (response?.data?.result) {
        const { token, details } = response?.data?.result;
        localStorage.setItem("accessToken", token);
        const userDetails = JSON.stringify(details);
        localStorage.setItem(USER_DATA_KEY, userDetails);
        toast.success(<CustomToast title={response?.data?.title} message={response?.data?.message || "Password set successfully."} />);
        navigate("/");
      } else {
        toast.error(<CustomToast title={response?.data?.error?.code} message={response?.data?.error?.message || "Invalid Data"}/>);
      }
    } catch (error) {
      console.error("Registration failed", error);
      toast.error(<CustomToast title={error?.code} message={error?.message || "Registration failed"}/>);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let errors = { ...validation.errors };

    switch (name) {
      case "password":
        if (!value) {
          errors.password = "Password is required";
        } else {
          errors.password = "";
        }
        break;
      case "confirmPassword":
        if (value !== validation.values.password) {
          errors.confirmPassword = "Passwords do not match";
        } else {
          errors.confirmPassword = "";
        }
        break;
      default:
        break;
    }

    setValidation((prevValidation) => ({
      ...prevValidation,
      values: {
        ...prevValidation.values,
        [name]: value,
      },
      errors: errors,
      touched: {
        ...prevValidation.touched,
        [name]: true,
      },
    }));
  };

  return (
      <div class="col-lg-9">
        <div className="card card-shadow">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-8 col-xl-6">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-3">
                  <h3 className="mb-5 text-center">Please set your password</h3>
                  <form onSubmit={handleFormSubmit} className="mt-4">
                    <div data-mdb-input-init className="form-outline mb-4">
                      <label className="form-label" htmlFor="typePassword">
                        Password
                      </label>
                      <input
                        type="password"
                        id="typePassword"
                        className="form-control form-control-lg"
                        name="password"
                        value={validation.values.password}
                        onChange={handleChange}
                      />
                      {validation.errors.password && (
                        <div className="text-danger">
                          {validation.errors.password}
                        </div>
                      )}
                    </div>

                    <div data-mdb-input-init className="form-outline mb-4">
                      <label
                        className="form-label"
                        htmlFor="typeConfirmPassword"
                      >
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        id="typeConfirmPassword"
                        className="form-control form-control-lg"
                        name="confirmPassword"
                        value={validation.values.confirmPassword}
                        onChange={handleChange}
                      />
                      {validation.errors.confirmPassword && (
                        <div className="text-danger">
                          {validation.errors.confirmPassword}
                        </div>
                      )}
                    </div>

                    <button
                      data-mdb-button-init
                      data-mdb-ripple-init
                      className="btn btn-primary btn-lg btn-block"
                      type="submit"
                    >
                      Submit
                    </button>
                  </form>
                  <hr className="my-4" />
                </div>
              </div>
            </div>
          </div>
        <ToastContainer />
        </div>
      </div>
  );
};

export default SetPassword;
