import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../Features/axios";
import { convertDateTimeUTCToLocal } from "../utilities/functions";
import NewsDetailsSkeleton from "./Skeleton/NewsDetailsSkeleton";

const NewsDeatilsSection = () => {
  const { id } = useParams();
  const [newsDetails, setNewsDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNewsContent = async () => {
      try {
        const response = await axiosInstance.post(`/admin/news/getNewsbyId`, {
          newsId: +id,
        });
        const newsContent = response?.data?.result;
        setNewsDetails(newsContent);
      } catch (error) {
        console.error("Error fetching page content:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsContent();
  }, [id]);

  return (
      <div class="col-lg-9">
        {loading ? (
          <NewsDetailsSkeleton />
        ) : (
          newsDetails && (
            <div class="card card-shadow p-0" key={newsDetails.newsId}>
              <div class="score-card score-card-lg p-0">
                <div class="score-card-inner px-20 py-20">
                  <div class="score-card-header mb-15">
                    <h1>{newsDetails?.tags}</h1>
                    <strong class="text-red">{newsDetails?.title}</strong>
                    <figure>
                      <img
                        src={newsDetails?.image || "/assets/images/flag.png"}
                        style={{ height: "500px", width: "100%" }}
                        alt=""
                      />
                    </figure>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: newsDetails?.news,
                      }}
                    />
                    <span>
                      {
                        convertDateTimeUTCToLocal(newsDetails?.startDate)
                          .localDate
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
  );
};

export default NewsDeatilsSection;
