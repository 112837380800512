import { useEffect, useState } from "react";
import axiosInstance from "../Features/axios";
import socket from "../Features/socket";
import {
  COMMENTARY_CONNECTION,
  COMMENTARY_DISCONNECTION,
  CONNECT,
  LIVE,
  RESULT,
  RUNNER_CONNECTION,
  UPCOMMING,
} from "../utilities/const";
import { sortMatchesByDateTime } from "./Reusables/Methods";
import MatchContentSkeleton from "./Skeleton/MatchContentSkeleton";
import NewsMatchSkeleton from "./Skeleton/NewsMatchSkeleton";
import Pagination from "./common/Pagination";
import MatchContentCard from "./MatchContentCard";
import { useAppContext } from "../Context/AppContext";

const MatchContent = ({ matchType, newsContent, loading, setLoading }) => {
  const [matchData, setMatchData] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [upcomingData, setUpcomingData] = useState([]);
  const [eventIds, setEventIds] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const { liveUpcomingData } = useAppContext();

  const fetchResultMatchData = async (page, limit) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `/admin/match/completeMatchesList`, { 
          page, 
          limit,
        }
      );
      if (response.data?.result) {
        const data = sortMatchesByDateTime(response.data?.result?.data, false);
        setMatchData(data || []);
        setEventIds(data?.map((match) => match?.eid));
        setTotalRecords(response.data?.result?.totalItems || 0);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const onShowSizeChange = (newCurrentPage, newPageSize) => {
    setLimit(newPageSize);
    setPage(newCurrentPage);
  };

  useEffect(() => {
    if(liveUpcomingData?.liveMatches || liveUpcomingData?.scheduleMatches) {
    const liveData = liveUpcomingData?.liveMatches && sortMatchesByDateTime(liveUpcomingData?.liveMatches, false);
    const upcomingData = liveUpcomingData?.scheduleMatches && sortMatchesByDateTime(liveUpcomingData?.scheduleMatches, false);
    setLiveData(liveData);
    setUpcomingData(upcomingData);
    setLoading(false);
    }
  }, [liveUpcomingData]);

  useEffect(() => {
    if (matchType === LIVE && liveData.length > 0) {
      setMatchData(liveData);
      setEventIds(liveData?.map((match) => match?.eid));
    } else if (matchType === UPCOMMING && upcomingData.length > 0) {
      setMatchData(upcomingData);
      setEventIds(upcomingData?.map((match) => match?.eid));
    }
  }, [matchType, liveData, upcomingData]);


  useEffect(()=>{
    if(matchType === RESULT) {
      window.scrollTo(0, 0);
      fetchResultMatchData(page, limit);
    }
  },[matchType, page, limit]);

  useEffect(() => {
    if (eventIds.length && matchType !== RESULT && socket) {
      if (socket?.connected) {
        socket.emit(COMMENTARY_CONNECTION, eventIds);
      } else {
        socket?.on(CONNECT, () => {
          socket?.emit(COMMENTARY_CONNECTION, eventIds);
        });
      }
    }
    return () => {
      if (socket) {
        socket?.emit(COMMENTARY_DISCONNECTION, eventIds);
      }
    };
  }, [eventIds]);

  useEffect(() => {
    if (eventIds.length && matchType !== RESULT && socket) {
      if (socket?.connected) {
        socket?.emit(RUNNER_CONNECTION, eventIds);
      } else {
        socket?.on(CONNECT, () => {
          socket?.emit(RUNNER_CONNECTION, eventIds);
        });
      }
    }
  }, [eventIds]);

  return (
    <div className="tab-content">
      <div id="live_match" className="tab-pane fade in show active">
        {newsContent && loading ? (
          <>
            <NewsMatchSkeleton />
            <NewsMatchSkeleton />
            <NewsMatchSkeleton />
          </>
        ) : loading ? (
          <>
            <MatchContentSkeleton />
            <MatchContentSkeleton />
            <MatchContentSkeleton />
          </>
        ) : (
        <>
          {matchData.length > 0 ? (
           matchData.map((item) => (
            <MatchContentCard
              key={item.eid}
              cardData={item}
              mt={matchType}
              newsContent={newsContent}
            />
            ))
          ) : (
            <p>No matches available.</p>
          )}
          {matchType === RESULT && (
              <Pagination
                totalRecords={totalRecords}
                page={page}
                limit={limit}
                setPage={setPage}
                onShowSizeChange={onShowSizeChange}
              />
            )}
        </>
        )}
      </div>
    </div>
  );
};

export default MatchContent;
